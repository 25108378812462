import React, { useState } from 'react'
import success_icon from '../../../assets/success_icon/s1.png'
import classes from './UploadButton.module.css'

export default function UploadButton(props) {
    const [success, setSuccess] = useState(false)
    const handleUpdate = () => {
        props.update()
        setSuccess(true)
        setTimeout(function () {
            setSuccess(false)
        }, 3000)
    }
    return (
        <>
            {props.image && !success && (
                <button
                    className={classes.btnUpload}
                    onClick={() => {
                        handleUpdate()
                        // window.location.reload(false)
                    }}>
                    Upload
                </button>
            )}
            {success && <div style={{ color: 'green' }}>{props.label} uploaded successfully!</div>}
        </>
    )
}
