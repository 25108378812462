import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthRoute } from '../components'
import { Auth, UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'

export default function AuthPage() {
    // Auth
    const { auth } = useParams()
    const token = auth
    const { dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)
    const navigate = useNavigate()

    useEffect(() => {
        let userFetch = async () => {
            let logFetch = await fetch(`${apiV1}/auth/`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                dataType: 'json',
                method: 'GET',
            })

            let log = await logFetch.json()

            if (logFetch.ok) {
                dispatchAuth({ type: 'token', payload: token })
                dispatchUser({ type: 'set', payload: log })
                navigate('/post-rfq')
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }
        }

        try {
            if (token) {
                userFetch()
            }
        } catch (e) {}
    }, [navigate, token])
    return <AuthRoute />
}
