import React from 'react'
import { BiImageAdd } from 'react-icons/bi'
import { useNavigate } from 'react-router'
import classes from './RfqUpload.module.css'

export default function RfqUpload(props) {
    const { activePopUp, setActivePopUp } = props

    // const img_name = props.currentImage ? `${apiV1}/images/logo/${props.currentImage}` : ''
    const navigate = useNavigate()
    // const [clicked, setClicked] = useState(false)

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }

    return (
        <div className={classes.wrapper}>
            <div>
                <div>✔</div>
                <p>The RFQ has been submitted successfully!</p>
            </div>
            <div>
                <button
                    onClick={() => {
                        setActivePopUp(false)
                        navigate('/my-rfq')
                    }}>
                    OK
                </button>
            </div>
            {/* <button
                    onClick={() => {
                        setActivePopUp(false)
                        navigate('/login')
                    }}>
                    Okay
                </button> */}
        </div>
    )
}
