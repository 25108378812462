import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layout, RfqPost } from '../components'
import { UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'
import { getBuyerById, getCategoryAll, getSellerById } from '../utils/functions'

export default function RfqPostPage() {
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const fileRef = useRef(null)

    // ------------------------------------------========= Set Select State =========------------------------------------------

    const [category, setCategory] = useState('')
    const [categoryOptions, setCategoryOptions] = useState('')
    const [businessCategory, setBusinessCategory] = useState('')
    const [country, setCountry] = useState({
        name: 'Bangladesh',
        code: 'BD',
        emoji: '🇧🇩',
        label: '🇧🇩 Bangladesh',
        value: 'Bangladesh',
    })

    // ------------------------------------------========= Select Unit =========------------------------------------------

    const [currency, setCurrency] = useState({ value: 'taka', label: '৳ - BDT', unit: '৳' })
    const [measurementUnit, setMeasurementUnit] = useState({ value: 'pieces', label: 'pieces', unit: 'pcs' })
    const [tradeTerms, setTradeTerms] = useState({ value: 'By Ship', label: 'By Ship' })

    const currencyOptions = [
        { value: 'dollar', label: '$ - USD', unit: '$' },
        { value: 'euro', label: '€ - EUR', unit: '€' },
        { value: 'taka', label: '৳ - BDT', unit: '৳' },
    ]

    const measurementUnitOptions = [
        { value: 'pieces', label: 'pieces', unit: 'pcs' },
        { value: 'm', label: 'm', unit: 'm' },
        { value: 'cm', label: 'cm', unit: 'cm' },
        { value: 'kg', label: 'kg', unit: 'kg' },
        { value: 'lbs', label: 'lbs', unit: 'lbs' },
    ]

    const tradeTermsOptions = [{ value: 'By Ship', label: 'By Ship' }]

    const businessCategoryOptions = [
        { value: 'Trading company', label: 'Tading company' },
        { value: 'Manufacture', label: 'Manufacture' },
    ]

    // ------------------------------------------========= Set File =========------------------------------------------

    const [file, setFile] = useState('')
    const [imageAlert, SetImageAlert] = useState(false)

    // ------------------------------------------========= Set rfq Description =========------------------------------------------

    const [rfq, setRfq] = useState({
        productName: '',
        budgetRange: '',
        currency: currency.value,
        quantity: 0,
        measurementUnit: measurementUnit.value,
        tradeTerms: tradeTerms.value,
        businessCategory: '',
        country: country.value,
        countryEmoji: country.emoji,
        address: '',
        companyName: '',
        companyAddress: '',
        deliveryAddress: '',
        category: '',
        email: '',
        phone: '',
        detailedRequirements: '',
        categoryId: 0,
        userId: userInfo.id ? userInfo.id : 0,
    })

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            if (userInfo.role_name === 'seller') {
                getSellerById(userInfo.id)
                    .then((items) => {
                        setRfq((prev) => {
                            return {
                                ...prev,
                                companyName: items.Seller.page_name,
                                companyAddress: items.Seller.city + ', ' + items.Seller.country,
                                email: items.User.email,
                                phone: items.User.phone,
                            }
                        })
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
            } else if (userInfo.role_name === 'buyer') {
                getBuyerById(userInfo.id)
                    .then((items) => {
                        setRfq((prev) => {
                            return {
                                ...prev,
                                companyName: items.Buyer.company_name,
                                email: items.User.email,
                                phone: items.User.phone,
                            }
                        })
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error)
                    })
            }
        }

        fetchData()
    }, [userInfo])

    const handleFileClick = () => {
        fileRef.current.click()
    }

    const [fileType, setFileType] = useState('')
    const handleFileChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        } else {
            const file = e.target.files[0]
            if (file.size > 5 * 1000 * 1024) {
                SetImageAlert(true)
                setTimeout(function () {
                    SetImageAlert(false)
                }, 5000)
            } else {
                setFile(file)
                setFileType(file.type.split('/')[1])
            }
        }
    }

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            getCategoryAll()
                .then((items) => {
                    setCategoryOptions(items)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [])

    const [alertSubmit, setAlertSubmit] = useState(false)
    const [btnClickPopUp, setBtnClickPopUp] = useState('')
    const [activePopUp, setActivePopUp] = useState(false)

    const handleSubmit = async (e) => {
        setAlertSubmit(true)
        e.preventDefault()
        try {
            const fileData = new FormData()
            fileData.append('file', file)
            const response = await fetch(
                `${apiV1}/rfq/?product_name=${rfq.productName}&requirements=${rfq.detailedRequirements}&budget_range=${rfq.budgetRange}&currency=${rfq.currency}&sourcing_quantity=${rfq.quantity}&measurement_unit=${rfq.measurementUnit}&country=${rfq.country}&country_emoji=${rfq.countryEmoji}&company=${rfq.companyName}&delivery_address=${rfq.deliveryAddress}&phone=${rfq.phone}&email=${rfq.email}&category_id=${rfq.categoryId}&user_id=${userInfo.id}`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        type: 'image/jpeg',
                        // 'Content-Type': 'application/json',
                        // Authorization: `Bearer ${token}`,
                    },
                    body: fileData,
                }
            )

            const log = await response.json()

            if (response.ok) {
                console.log('success')
                setBtnClickPopUp('4')
                setActivePopUp(true)
                setAlertSubmit(false)
            } else {
                console.log('failed')
                alert(log.context + '!')
                setAlertSubmit(false)
            }
        } catch {
            alert('Please check your internet connection!')
            setAlertSubmit(false)
        }
    }

    return (
        <Layout>
            <RfqPost
                rfq={rfq}
                setRfq={setRfq}
                category={category}
                setCategory={setCategory}
                categoryOptions={categoryOptions}
                currency={currency}
                setCurrency={setCurrency}
                measurementUnit={measurementUnit}
                setMeasurementUnit={setMeasurementUnit}
                currencyOptions={currencyOptions}
                measurementUnitOptions={measurementUnitOptions}
                tradeTerms={tradeTerms}
                setTradeTerms={setTradeTerms}
                tradeTermsOptions={tradeTermsOptions}
                businessCategory={businessCategory}
                setBusinessCategory={setBusinessCategory}
                businessCategoryOptions={businessCategoryOptions}
                country={country}
                setCountry={setCountry}
                file={file}
                setFile={setFile}
                fileRef={fileRef}
                fileType={fileType}
                imageAlert={imageAlert}
                handleFileClick={handleFileClick}
                handleFileChange={handleFileChange}
                handleSubmit={handleSubmit}
                alertSubmit={alertSubmit}
                btnClickPopUp={btnClickPopUp}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
            />
        </Layout>
    )
}
