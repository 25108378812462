import React from 'react'
import { BsCloudUploadFill } from 'react-icons/bs'
import { BsFileEarmarkPdfFill } from 'react-icons/bs'
import { CgCloseO } from 'react-icons/cg'
import PhoneInputField from '../PhoneInputField/PhoneInputField'
import { SelectField } from '../SelectField/SelectField'
import classes from './InputField.module.css'

export default function InputField(props) {
    return (
        <div className={classes.wrapper}>
            <label>{props.label}</label>
            {/* {props.disabled ? ':' : ''} */}
            {props.type === 'textArea' ? (
                <textarea
                    disabled={props.disabled}
                    required={props.required}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value ? props.value : ''}
                    onChange={props.onChange}
                />
            ) : props.type === 'select' ? (
                <div className={classes.selectField}>
                    <SelectField
                        search_by={props.search_by}
                        searchable={props.searchable}
                        options={props.options}
                        value={props.value}
                        selectOnChange={props.selectOnChange}
                    />
                    <input
                        disabled={props.disabled}
                        required={props.required}
                        value={props.value ? props.value : ''}
                        onChange={(e) => e.target.value}
                    />
                </div>
            ) : props.inputUnit ? (
                <>
                    <div className={classes.inputCurrency}>
                        <div>{props.unit}</div>
                        <input
                            disabled={props.disabled}
                            required={props.required}
                            type={props.type}
                            placeholder={props.placeholder}
                            value={props.value ? props.value : ''}
                            onChange={props.onChange}
                        />
                    </div>
                </>
            ) : props.type === 'tel' ? (
                <>
                    <div className={classes.inputTel}>
                        <PhoneInputField
                            placeholder="Phone"
                            defaultCountry="BD"
                            data={props.value}
                            setData={props.onChange}
                        />
                        <input
                            disabled={props.disabled}
                            required={props.required}
                            value={props.value ? props.value : ''}
                            onChange={(e) => e.target.value}
                        />
                    </div>
                </>
            ) : props.type === 'file' ? (
                <>
                    <div className={classes.inputFile}>
                        <div className={classes.inputClick} onClick={props.handleFileClick}>
                            <div>
                                <BsCloudUploadFill />
                            </div>
                            <div>max size 3mb</div>
                            <input
                                disabled={props.disabled}
                                required={props.required}
                                type={props.type}
                                onChange={props.onChange}
                                ref={props.fileRef}
                            />
                        </div>
                        {props.file && (
                            <>
                                <div className={classes.file}>
                                    {props.fileType === 'pdf' ? (
                                        <div className={classes.pdf}>
                                            <BsFileEarmarkPdfFill className={classes.pdfIcon} />
                                            PDF File
                                        </div>
                                    ) : (
                                        <img src={URL.createObjectURL(props.file)} alt="" />
                                    )}
                                    <div className={classes.cancel} onClick={() => props.setFile(null)}>
                                        <CgCloseO className={classes.iconCancel} />
                                    </div>
                                </div>
                            </>
                        )}
                        {props.imageAlert && <div className={classes.alertImage}>File size must be less than 3mb!</div>}
                    </div>
                </>
            ) : (
                <input
                    disabled={props.disabled}
                    required={props.required}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value ? props.value : ''}
                    onChange={props.onChange}
                />
            )}
        </div>
    )
}
