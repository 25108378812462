import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layout, ProductAdd } from '../components'
import { UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'

export default function ProductAddPage() {
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    // ------------------------------------------========= Set Category =========------------------------------------------

    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [subSubCategory, setSubSubCategory] = useState('')

    // ------------------------------------------========= Select Unit =========------------------------------------------

    const [currency, setCurrency] = useState({ value: 'dollar', label: '$ - USD', unit: '$' })
    const [weightUnit, setWeightUnit] = useState({ value: 'kg', label: 'kg' })
    const [measurementUnit, setMeasurmentUnit] = useState({ value: 'm', label: 'm' })

    const currencyOptions = [
        { value: 'dollar', label: '$ - USD', unit: '$' },
        { value: 'euro', label: '€ - EUR', unit: '€' },
        { value: 'taka', label: '৳ - BDT', unit: '৳' },
    ]

    const weightUnitOptions = [
        { value: 'kg', label: 'kg' },
        { value: 'lbs', label: 'lbs' },
    ]

    const measurementUnitOptions = [
        { value: 'm', label: 'm' },
        { value: 'cm', label: 'cm' },
    ]

    // ------------------------------------------========= Set Product Description =========------------------------------------------

    const [product, setProduct] = useState({
        title: '',
        description: '',
        quantity: 0,
        sku: '',
        category_id: 0,
        sub_category_id: 0,
        sub_sub_category_id: 0,
        user_id: userInfo.id ? userInfo.id : 0,
    })

    const [price, setPrice] = useState({
        price: 0,
        currency: '',
        moq: 0,
        discount: 0,
        offer_price: 0,
        moq2: 0,
        discount2: 0,
        offer_price2: 0,
        moq3: 0,
        discount3: 0,
        offer_price3: 0,
    })

    const [packages, setPackages] = useState({
        weight: 0,
        weight_unit: '',
        length: 0,
        width: 0,
        height: 0,
        measurement_unit: '',
    })

    const [attributes, setAttributes] = useState({
        name: '',
        value: '',
        name2: '',
        value2: '',
        name3: '',
        value3: '',
        name4: '',
        value4: '',
        name5: '',
        value5: '',
        name6: '',
        value6: '',
        name7: '',
        value7: '',
        name8: '',
        value8: '',
        name9: '',
        value9: '',
        name10: '',
        value10: '',
    })

    // ------------------------------------------========= Add more attribute =========------------------------------------------

    const [attribute, setAttribute] = useState([
        {
            label: '',
            value: '',
        },
    ])

    const handleInputChange = (e, index) => {
        const list = [...attribute]
        e ? (list[index][e.target.name] = e.target.value) : (list[index][e.target.name] = '')
        setAttribute(list)
        attribute &&
            setAttributes({
                ...attributes,
                name6: attribute[0] ? attribute[0].label : '',
                value6: attribute[0] ? attribute[0].value : '',
                name7: attribute[1] ? attribute[1].label : '',
                value7: attribute[1] ? attribute[1].value : '',
                name8: attribute[2] ? attribute[2].label : '',
                value8: attribute[2] ? attribute[2].value : '',
                name9: attribute[3] ? attribute[3].label : '',
                value9: attribute[3] ? attribute[3].value : '',
                name10: attribute[4] ? attribute[4].label : '',
                value10: attribute[4] ? attribute[4].value : '',
            })
    }

    const handleAddClick = () => {
        setAttribute([...attribute, { label: '', value: '' }])
    }

    const setAllAttribute = () => {
        setAttributes({
            ...attributes,
            name6: attribute[0] ? attribute[0].label : '',
            value6: attribute[0] ? attribute[0].value : '',
            name7: attribute[1] ? attribute[1].label : '',
            value7: attribute[1] ? attribute[1].value : '',
            name8: attribute[2] ? attribute[2].label : '',
            value8: attribute[2] ? attribute[2].value : '',
            name9: attribute[3] ? attribute[3].label : '',
            value9: attribute[3] ? attribute[3].value : '',
            name10: attribute[4] ? attribute[4].label : '',
            value10: attribute[4] ? attribute[4].value : '',
        })
    }

    const handleRemoveClick = (index) => {
        const list = [...attribute]
        list.splice(index, 1)
        setAttribute(list)
        attribute && setAllAttribute()
    }

    // ------------------------------------------========= Category Option =========------------------------------------------

    const [categoryOption, setCategoryOption] = useState([])
    const [subCategoryOption, setSubCategoryOption] = useState([])
    const [subSubCategoryOption, setSubSubCategoryOption] = useState([])

    // ------------------------------------------========= Set Product Image =========------------------------------------------

    const [imageAlert, setImageAlert] = useState(false)
    const [displayImage, setDisplayImage] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null)
    const [image4, setImage4] = useState(null)
    const [image5, setImage5] = useState(null)

    const displayImageRef = useRef(null)
    const image2Ref = useRef(null)
    const image3Ref = useRef(null)
    const image4Ref = useRef(null)
    const image5Ref = useRef(null)

    const handleDisplayClick = () => {
        displayImageRef.current.click()
    }

    const handleImg2Click = () => {
        image2Ref.current.click()
    }

    const handleImg3Click = () => {
        image3Ref.current.click()
    }

    const handleImg4Click = () => {
        image4Ref.current.click()
    }

    const handleImg5Click = () => {
        image5Ref.current.click()
    }

    const handleDisplayChange = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setDisplayImage(null)
            return
        }
        const file = e.target.files[0]
        setImageAlert(false)
        if (file.size > 1 * 1000 * 1024) {
            setDisplayImage(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 10000)
        } else {
            setDisplayImage(file)
        }
    }

    const handleImage2Change = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage2(null)
            return
        }
        const file = e.target.files[0]
        if (file.size > 1 * 1000 * 1024) {
            setImage2(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImage2(file)
        }
    }

    const handleImage3Change = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage3(null)
            return
        }
        const file = e.target.files[0]
        if (file.size > 1 * 1000 * 1024) {
            setImage3(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImage3(file)
        }
    }

    const handleImage4Change = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage4(null)
            return
        }
        const file = e.target.files[0]
        if (file.size > 1 * 1000 * 1024) {
            setImage4(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImage4(file)
        }
    }

    const handleImage5Change = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage5(null)
            return
        }
        const file = e.target.files[0]
        if (file.size > 1 * 1000 * 1024) {
            setImage5(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImage5(file)
        }
    }

    useEffect(() => {
        const fetchCategory = async () => {
            const fetchConfigurationGet = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            }
            const categoryOptionList = []
            const subCategoryOptionList = []
            const subSubCategoryOptionList = []
            try {
                const responseCategory = await fetch(`${apiV1}/categories/`, fetchConfigurationGet)

                const data = await responseCategory.json()

                if (responseCategory.ok) {
                    await data.map((e) => categoryOptionList.push({ id: e.id, label: e.name, value: e.name }))
                    setCategoryOption(categoryOptionList)
                    // console.log(category.id)
                    if (category) {
                        const responseSubCategory = await fetch(
                            `${apiV1}/categories/sub_category/${category.id}`,
                            fetchConfigurationGet
                        )

                        const data = await responseSubCategory.json()

                        if (responseSubCategory.ok) {
                            await data.map((e) =>
                                subCategoryOptionList.push({ id: e.id, label: e.name, value: e.name })
                            )
                            setSubCategoryOption(subCategoryOptionList)
                            if (subCategory) {
                                const responseSubSubCategory = await fetch(
                                    `${apiV1}/categories/sub_sub_category/${subCategory.id}`,
                                    fetchConfigurationGet
                                )

                                const data = await responseSubSubCategory.json()

                                if (responseSubSubCategory.ok) {
                                    await data.map((e) =>
                                        subSubCategoryOptionList.push({ id: e.id, label: e.name, value: e.name })
                                    )
                                    setSubSubCategoryOption(subSubCategoryOptionList)
                                }
                            }
                        }
                    }
                }
            } catch {}
        }
        fetchCategory()
    }, [category, subCategory, subSubCategory])

    useEffect(() => {
        const discount = () => {
            price.price && price.discount
                ? setPrice((prev) => {
                      return { ...prev, offer_price: price.price - (price.discount * price.price) / 100 }
                  })
                : setPrice((prev) => {
                      return { ...prev, offer_price: 0 }
                  })
            price.price && price.discount2
                ? setPrice((prev) => {
                      return { ...prev, offer_price2: price.price - (price.discount2 * price.price) / 100 }
                  })
                : setPrice((prev) => {
                      return { ...prev, offer_price2: 0 }
                  })

            price.price && price.discount2
                ? setPrice((prev) => {
                      return { ...prev, offer_price3: price.price - (price.discount3 * price.price) / 100 }
                  })
                : setPrice((prev) => {
                      return { ...prev, offer_price3: 0 }
                  })
        }
        discount()
    }, [price.price, price.discount, price.discount2, price.discount3])

    const [btnClickPopUp, setBtnClickPopUp] = useState('')
    const [activePopUp, setActivePopUp] = useState(false)
    const handleProductUploadPopUp = () => {
        setActivePopUp(true)
        setBtnClickPopUp('1')
    }

    const handleImageUploadPopUp = () => {
        setActivePopUp(true)
        setBtnClickPopUp('2')
    }

    const [alertSubmit, setAlertSubmit] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        const details = {
            Product: {
                title: product.title,
                description: product.description,
                quantity: product.quantity,
                sku: product.sku,
                category_id: category.id,
                sub_category_id: subCategory.id,
                sub_sub_category_id: subSubCategory.id,
                user_id: product.user_id,
            },
            Price: {
                price: price.price,
                currency: currency.value,
                moq: price.moq,
                discount: price.discount,
                offer_price: price.offer_price,
                moq2: price.moq2,
                discount2: price.discount2,
                offer_price2: price.offer_price2,
                moq3: price.moq3,
                discount3: price.discount3,
                offer_price3: price.offer_price3,
            },
            Package: {
                weight: packages.weight,
                weight_unit: weightUnit.value,
                length: packages.length,
                width: packages.width,
                height: packages.height,
                measurement_unit: measurementUnit.value,
            },
            Attribute: {
                name: 'Product size',
                value: attributes.value,
                name2: 'Material',
                value2: attributes.value2,
                name3: 'Color',
                value3: attributes.value3,
                name4: 'Supply Ability  ',
                value4: attributes.value4,
                name5: 'Delivery Time',
                value5: attributes.value5,
                name6: attributes.name6,
                value6: attributes.value6,
                name7: attributes.name7,
                value7: attributes.value7,
                name8: attributes.name8,
                value8: attributes.value8,
                name9: attributes.name9,
                value9: attributes.value9,
                name10: attributes.name10,
                value10: attributes.value10,
            },
        }

        if (displayImage) {
            setAlertSubmit(true)
            setTimeout(function () {
                setAlertSubmit(false)
            }, 5000)
            try {
                const response = await fetch(`${apiV1}/products/`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    localStorage.setItem('productId', log.id)

                    if (displayImage) {
                        const ImageForm = new FormData()
                        ImageForm.append('img', displayImage)
                        image2 && ImageForm.append('img2', image2)
                        image3 && ImageForm.append('img3', image3)
                        image4 && ImageForm.append('img4', image4)
                        image5 && ImageForm.append('img5', image5)
                        try {
                            const productId = parseInt(localStorage.getItem('productId'))
                            const response = await fetch(`${apiV1}/products/image/${productId}`, {
                                headers: {
                                    Accept: 'application/json',
                                    type: 'image/jpeg',
                                    // Authorization: `Bearer ${token}`,
                                },
                                method: 'PUT',
                                body: ImageForm,
                            })

                            // const log = await response.json()

                            if (response.ok) {
                                handleProductUploadPopUp()
                            }
                        } catch (e) {
                            console.log('Please check your internet connection!', e)
                        }
                    }
                } else {
                    alert(log.context + '!')
                }
            } catch (e) {
                console.log('Please check your internet connection!', e)
            }
        } else {
            handleImageUploadPopUp()
        }
    }

    return (
        <Layout>
            <ProductAdd
                userInfo={userInfo}
                category={category}
                setCategory={setCategory}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                subSubCategory={subSubCategory}
                setSubSubCategory={setSubSubCategory}
                product={product}
                setProduct={setProduct}
                price={price}
                setPrice={setPrice}
                packages={packages}
                setPackages={setPackages}
                attributes={attributes}
                setAttributes={setAttributes}
                attribute={attribute}
                setAttribute={setAttribute}
                currency={currency}
                setCurrency={setCurrency}
                weightUnit={weightUnit}
                setWeightUnit={setWeightUnit}
                measurementUnit={measurementUnit}
                setMeasurmentUnit={setMeasurmentUnit}
                currencyOptions={currencyOptions}
                weightUnitOptions={weightUnitOptions}
                measurementUnitOptions={measurementUnitOptions}
                handleInputChange={handleInputChange}
                handleAddClick={handleAddClick}
                handleRemoveClick={handleRemoveClick}
                categoryOption={categoryOption}
                setCategoryOption={setCategoryOption}
                subCategoryOption={subCategoryOption}
                setSubCategoryOption={setSubCategoryOption}
                subSubCategoryOption={subSubCategoryOption}
                setSubSubCategoryOption={setSubSubCategoryOption}
                handleDisplayClick={handleDisplayClick}
                handleImg2Click={handleImg2Click}
                handleImg3Click={handleImg3Click}
                handleImg4Click={handleImg4Click}
                handleImg5Click={handleImg5Click}
                displayImageRef={displayImageRef}
                image2Ref={image2Ref}
                image3Ref={image3Ref}
                image4Ref={image4Ref}
                image5Ref={image5Ref}
                displayImage={displayImage}
                image2={image2}
                image3={image3}
                image4={image4}
                image5={image5}
                setDisplayImage={setDisplayImage}
                setImage2={setImage2}
                setImage3={setImage3}
                setImage4={setImage4}
                setImage5={setImage5}
                handleDisplayChange={handleDisplayChange}
                handleImage2Change={handleImage2Change}
                handleImage3Change={handleImage3Change}
                handleImage4Change={handleImage4Change}
                handleImage5Change={handleImage5Change}
                imageAlert={imageAlert}
                btnClickPopUp={btnClickPopUp}
                setBtnClickPopUp={setBtnClickPopUp}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
                alertSubmit={alertSubmit}
                setAlertSubmit={setAlertSubmit}
                handleSubmit={handleSubmit}
            />
        </Layout>
    )
}
