import React from 'react'
import { Layout, Orders } from '../components'

export default function OrdersPage() {
    return (
        <Layout>
            <Orders />
        </Layout>
    )
}
