import React from 'react'
import classes from './SubmitButton.module.css'

export default function SubmitButton(props) {
    return (
        <>
            {props.buttonFor === '2' ? (
                props.alertSubmit ? (
                    'Loading..'
                ) : (
                    <button type={props.type} className={classes.btnUpdate}>
                        Submit
                    </button>
                )
            ) : (
                <>
                    {props.updateSave && (
                        <button
                            className={classes.btnCancel}
                            onClick={() => {
                                props.setUpdateSave(false)
                                props.getPageData()
                            }}>
                            Cancel
                        </button>
                    )}
                    <button
                        className={classes.btnUpdate}
                        onClick={() => {
                            if (!props.updateSave) {
                                props.setUpdateSave(true)
                            } else {
                                props.setUpdateSave(false)
                                props.updateInformation && props.updateInformation()
                            }
                        }}>
                        {!props.updateSave ? 'Update' : 'Save'}
                    </button>
                </>
            )}
        </>
    )
}
