import { useContext, useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { Auth, UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'

export default function ProtectedRoute({ preventUser = [] }) {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)

    let token = stateAuth.token

    const navigate = useNavigate()

    useEffect(() => {
        let userFetch = async () => {
            let logFetch = await fetch(`${apiV1}/auth/`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                dataType: 'json',
                method: 'GET',
            })

            let log = await logFetch.json()

            if (logFetch.ok) {
                dispatchAuth({ type: 'token', payload: token })
                dispatchUser({ type: 'set', payload: log })
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }

            //role check
            if (preventUser.indexOf(log.role_name) !== -1) {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
                navigate('/')
            }
        }

        try {
            userFetch()
        } catch (e) {
            dispatchAuth({ type: 'remove' })
            dispatchUser({ type: 'remove' })
        }
    }, [navigate])
    //dispatchUser, api, token, dispatchAuth, preventUser

    return stateAuth.auth === true ? <Outlet /> : <Navigate to="/" />
}
