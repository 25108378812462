import React from 'react'
import { countryListEmoji } from '../../../utils/countryListEmoji'
import { Body, InputField, PopUp, SubmitButton } from '../../Resource'
import { SelectField } from '../../Resource/SelectField/SelectField'
import classes from './RfqPost.module.css'

export default function RfqPost(props) {
    return (
        <Body>
            <PopUp
                btnClickPopUp={props.btnClickPopUp}
                activePopUp={props.activePopUp}
                setActivePopUp={props.setActivePopUp}
            />
            <div className={classes.wrapper}>
                <form onSubmit={(e) => props.handleSubmit(e)}>
                    <div>Request for Quote</div>
                    <div className={classes.rfq}>
                        <div>
                            <p>Post your request</p>
                            <div>
                                <InputField
                                    label="Product Name*"
                                    placeholder={'Enter your product name'}
                                    disabled={false}
                                    required={true}
                                    type="text"
                                    value={props.rfq.productName}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, productName: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, productName: null }
                                              })
                                    }
                                />

                                <InputField
                                    label="Product Category*"
                                    disabled={false}
                                    required={true}
                                    type="select"
                                    search_by="-- Select product category --"
                                    searchable={false}
                                    options={props.categoryOptions}
                                    value={props.category}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setCategory(e)
                                            props.setRfq((prev) => {
                                                return { ...prev, category: e.value, categoryId: e.id }
                                            })
                                        } else {
                                            props.setCategory('')
                                            props.setRfq((prev) => {
                                                return { ...prev, category: '', categoryId: '' }
                                            })
                                        }
                                    }}
                                />

                                <InputField
                                    label="Detailed Requirement*"
                                    placeholder={'Enter your product requirements in detail'}
                                    disabled={false}
                                    required={true}
                                    type="textArea"
                                    value={props.rfq.detailedRequirements}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, detailedRequirements: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, detailedRequirements: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="File/Image"
                                    disabled={false}
                                    required={false}
                                    type="file"
                                    fileType={props.fileType}
                                    file={props.file}
                                    setFile={props.setFile}
                                    onChange={props.handleFileChange}
                                    handleFileClick={props.handleFileClick}
                                    fileRef={props.fileRef}
                                    imageAlert={props.imageAlert}
                                />
                                <div className={classes.pricingField}>
                                    <InputField
                                        label="Budget Range*"
                                        placeholder={'ex. 500-2000'}
                                        disabled={false}
                                        required={true}
                                        type="text"
                                        inputUnit={true}
                                        unit={props.currency.unit}
                                        value={props.rfq.budgetRange}
                                        onChange={(e) =>
                                            e
                                                ? props.setRfq((prev) => {
                                                      return { ...prev, budgetRange: e.target.value }
                                                  })
                                                : props.setRfq((prev) => {
                                                      return { ...prev, budgetRange: null }
                                                  })
                                        }
                                    />
                                    <div>
                                        <p>Select Currency</p>
                                        <SelectField
                                            search_by="-- Select currency --"
                                            searchable={false}
                                            options={props.currencyOptions}
                                            value={props.currency}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    props.setCurrency(e)
                                                    props.setRfq((prev) => {
                                                        return { ...prev, currency: e.value }
                                                    })
                                                } else {
                                                    props.setCurrency('')
                                                    props.setRfq((prev) => {
                                                        return { ...prev, currency: '' }
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.pricingField}>
                                    <InputField
                                        label="Sourcing Quantity*"
                                        placeholder={'ex. 4000'}
                                        disabled={false}
                                        required={true}
                                        type="text"
                                        inputUnit={true}
                                        unit={props.measurementUnit.unit}
                                        value={props.rfq.quantity}
                                        onChange={(e) =>
                                            e
                                                ? props.setRfq((prev) => {
                                                      return { ...prev, quantity: e.target.value }
                                                  })
                                                : props.setRfq((prev) => {
                                                      return { ...prev, quantity: null }
                                                  })
                                        }
                                    />
                                    <div>
                                        <p>Select Measurement</p>
                                        <SelectField
                                            search_by="-- Select measurement --"
                                            searchable={false}
                                            options={props.measurementUnitOptions}
                                            value={props.measurementUnit}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    props.setMeasurementUnit(e)
                                                    props.setRfq((prev) => {
                                                        return { ...prev, measurementUnit: e.value }
                                                    })
                                                } else {
                                                    props.setCurrency('')
                                                    props.setRfq((prev) => {
                                                        return { ...prev, measurementUnit: '' }
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* <InputField
                                    label="Trade Terms*"
                                    disabled={false}
                                    required={true}
                                    type="select"
                                    search_by="-- Select trade terms --"
                                    searchable={false}
                                    options={props.tradeTermsOptions}
                                    value={props.tradeTerms}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setTradeTerms(e)
                                            props.setRfq((prev) => {
                                                return { ...prev, tradeTerms: e.value }
                                            })
                                        } else {
                                            props.setTradeTerms('')
                                            props.setRfq((prev) => {
                                                return { ...prev, tradeTerms: '' }
                                            })
                                        }
                                    }}
                                /> */}

                                {/* <InputField
                                    label="Business category"
                                    disabled={false}
                                    required={false}
                                    type="select"
                                    search_by="-- Select business category --"
                                    searchable={false}
                                    options={props.businessCategoryOptions}
                                    value={props.businessCategory}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setBusinessCategory(e)
                                            props.setRfq((prev) => {
                                                return { ...prev, businessCategory: e.value }
                                            })
                                        } else {
                                            props.setBusinessCategory('')
                                            props.setRfq((prev) => {
                                                return { ...prev, businessCategory: '' }
                                            })
                                        }
                                    }}
                                /> */}

                                <InputField
                                    label="Country*"
                                    disabled={false}
                                    required={true}
                                    type="select"
                                    search_by="-- Select country --"
                                    searchable={true}
                                    options={countryListEmoji}
                                    value={props.country}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setCountry(e)
                                            props.setRfq((prev) => {
                                                return { ...prev, country: e.value, countryEmoji: e.emoji }
                                            })
                                        } else {
                                            props.setCurrency('')
                                            props.setRfq((prev) => {
                                                return { ...prev, country: '', countryEmoji: '' }
                                            })
                                        }
                                    }}
                                />

                                <InputField
                                    label="Company Name*"
                                    placeholder={''}
                                    disabled={false}
                                    required={false}
                                    type="name"
                                    value={props.rfq.companyName}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, companyName: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, companyName: null }
                                              })
                                    }
                                />

                                <InputField
                                    label="Company Address*"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.rfq.companyAddress}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, companyAddress: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, companyAddress: null }
                                              })
                                    }
                                />

                                <InputField
                                    label="Delivery Address*"
                                    placeholder={'ex. House 83, Hajipara, Dhaka'}
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.rfq.deliveryAddress}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, deliveryAddress: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, deliveryAddress: null }
                                              })
                                    }
                                />

                                <InputField
                                    label="Email*"
                                    placeholder={'ex. example@gmail.com'}
                                    disabled={false}
                                    required={true}
                                    type="email"
                                    value={props.rfq.email}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, email: e.target.value }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, email: null }
                                              })
                                    }
                                />

                                <InputField
                                    label="Phone*"
                                    disabled={false}
                                    required={true}
                                    placeholder="Phone"
                                    defaultCountry="BD"
                                    type="tel"
                                    value={props.rfq.phone}
                                    onChange={(e) =>
                                        e
                                            ? props.setRfq((prev) => {
                                                  return { ...prev, phone: e }
                                              })
                                            : props.setRfq((prev) => {
                                                  return { ...prev, phone: null }
                                              })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <SubmitButton alertSubmit={props.alertSubmit} buttonFor="2" type="submit" />
                    </div>
                </form>
            </div>
        </Body>
    )
}
