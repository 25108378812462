import React from 'react'
import { RiDeleteBack2Line } from 'react-icons/ri'
import { SelectField } from '../SelectField/SelectField'
import classes from './DoubleInputField.module.css'

export default function DoubleInputField(props) {
    return (
        <div className={classes.wrapper}>
            <input
                disabled={props.disabled}
                required={props.required}
                type={props.type1}
                name={props.name1}
                placeholder={props.placeholder1}
                value={props.value1}
                onChange={props.onChange1}
            />

            <input
                disabled={props.disabled}
                required={props.required}
                type={props.type2}
                name={props.name2}
                placeholder={props.placeholder2}
                value={props.value2}
                onChange={props.onChange2}
            />

            <button className={classes.btnRemove} type="button" onClick={() => props.handleRemoveClick(props.index)}>
                <RiDeleteBack2Line className={classes.iconStyle} />
            </button>
        </div>
    )
}
