import React from 'react'
import { Inquiries, Layout } from '../components'

export default function InquiriesPage() {
    return (
        <Layout>
            <Inquiries />
        </Layout>
    )
}
