import React from 'react'
import { Layout, Logistics } from '../components'

export default function LogisticPage() {
    return (
        <Layout>
            <Logistics />
        </Layout>
    )
}
