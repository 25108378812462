import { BiImageAdd } from 'react-icons/bi'
import { apiV1 } from '../../../utils/api'
import UploadButton from '../UploadButton/UploadButton'
import classes from './FileInputField.module.css'

export default function FileInputField(props) {
    const img_name = props.currentImage ? `${apiV1}/images/${props.imageOf}/${props.currentImage}` : ''
    // label="Logo"
    //                 type="file"
    //                 value=""
    //                 update={props.updateLogo}
    //                 image={props.imageLogo}
    //                 setImage={props.setImageLogo}
    //                 handleImageChange={props.handleImageChangeLogo}
    //                 handleImageClick={props.handleImageClick}
    //                 inputRef={props.inputRef}
    return (
        <div className={classes.wrapperFile}>
            <label>{props.label}</label>
            <div className={classes.wrapperFileImage}>
                <div className={classes.wrapperImage} onClick={props.handleImageClick}>
                    <BiImageAdd className={classes.iconStyle} />
                    <p>Click to upload {props.label}</p>
                    <p>PNG, JPG (max 500 x 500px)</p>
                    <input type={props.type} disabled={false} onChange={props.handleImageChange} ref={props.inputRef} />
                </div>
                {props.image ? (
                    <div className={props.label === 'Logo' ? classes.logo : classes.banner}>
                        <img src={URL.createObjectURL(props.image)} alt="" />
                    </div>
                ) : (
                    <div className={props.label === 'Logo' ? classes.logo : classes.banner}>
                        <img src={img_name ? img_name : ''} alt="" />
                    </div>
                )}
            </div>

            <UploadButton label={props.label} image={props.image} setImage={props.setImage} update={props.update} />
        </div>
    )
}
