import React from 'react'
import classes from './ComingSoon.module.css'

export default function ComingSoon(props) {
    return (
        <div className={classes.wrapper}>
            <div>{props.title}</div>
            <div>Coming Soon!</div>
        </div>
    )
}
