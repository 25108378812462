import React, { useState } from 'react'
import { InputField, SubmitButton } from '../../Resource'
import classes from './BusinessInformation.module.css'

export default function BusinessInformation(props) {
    const [updateSave, setUpdateSave] = useState(false)
    return (
        <div className={classes.wrapper}>
            <div>
                <p>Update your Business Details</p>
                <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateBusinessInformation}
                        getPageData={props.getPageData}
                    />
                </div>
            </div>

            <div>
                <InputField
                    label="Registration Date"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="date"
                    value={props.registrationDate ? props.registrationDate : ''}
                    onChange={(e) => props.setRegistrationDate(e.target.value)}
                />
                <InputField
                    label="Year of Exporting"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.yearsExport ? props.yearsExport : ''}
                    onChange={(e) => props.setYearsExport(e.target.value)}
                />
                <InputField
                    label="Annual Export"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.annualExport ? props.annualExport : ''}
                    onChange={(e) => props.setAnnualExport(e.target.value)}
                />
                <InputField
                    label="Floor Space"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.floorSpace ? props.floorSpace : ''}
                    onChange={(e) => props.setFloorSpace(e.target.value)}
                />
                <InputField
                    label="Production Machine"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.productionMachine ? props.productionMachine : ''}
                    onChange={(e) => props.setProductionMachine(e.target.value)}
                />
                <InputField
                    label="Production Line"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.productionLines ? props.productionLines : ''}
                    onChange={(e) => props.setProductionLines(e.target.value)}
                />
                <InputField
                    label="Annual Output"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.totalAnnualOutput ? props.totalAnnualOutput : ''}
                    onChange={(e) => props.setTotalAnnualOutput(e.target.value)}
                />
                <InputField
                    label="Supply Partner"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.supplyChain ? props.supplyChain : ''}
                    onChange={(e) => props.setSupplyChain(e.target.value)}
                />
                <InputField
                    label="Main Market"
                    disabled={!updateSave ? true : false}
                    required={true}
                    type="text"
                    value={props.mainMarket ? props.mainMarket : ''}
                    onChange={(e) => props.setMainMarket(e.target.value)}
                />
            </div>
            <div>
                <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateBusinessInformation}
                        getPageData={props.getPageData}
                    />
                </div>
            </div>
        </div>
    )
}
