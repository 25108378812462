import React, { useState } from 'react'
import { FileInputField, InputField, SubmitButton } from '../../Resource'
import classes from './LogoBanner.module.css'

export default function LogoBanner(props) {
    const [updateSave, setUpdateSave] = useState(false)

    return (
        <div className={classes.wrapper}>
            <div>
                <p>Update your Logo and Banner</p>
                {/* <div>
                    <SubmitButton updateSave={updateSave} setUpdateSave={setUpdateSave} />
                </div> */}
                {/* updateLogo={updateLogo}
                imageLogo={imageLogo}
                setImageLogo={setImageLogo}
                handleLogoClick={handleLogoClick}
                handleLogoChange={handleLogoChange}
                logoRef={logoRef}
                updateBanner={updateBanner}
                imageBanner={imageBanner}
                setImageBanner={setImageBanner}
                handleBannerClick={handleBannerClick}
                handleBannerChange={handleBannerChange}
                bannerRef={bannerRef} */}
            </div>
            <div>
                <FileInputField
                    label="Logo"
                    type="file"
                    value=""
                    imageOf="logo"
                    update={props.updateLogo}
                    image={props.imageLogo}
                    setImage={props.setImageLogo}
                    handleImageClick={props.handleLogoClick}
                    handleImageChange={props.handleLogoChange}
                    inputRef={props.logoRef}
                    currentImage={props.currentLogo}
                    imageAlert={props.imageAlert}
                />
                <FileInputField
                    label="Banner"
                    type="file"
                    value=""
                    imageOf="banner"
                    update={props.updateBanner}
                    image={props.imageBanner}
                    setImage={props.setImageBanner}
                    handleImageClick={props.handleBannerClick}
                    handleImageChange={props.handleBannerChange}
                    inputRef={props.bannerRef}
                    currentImage={props.currentBanner}
                    imageAlert={props.imageAlert}
                />
            </div>
        </div>
    )
}
