import React from 'react'
import { Dashboard, Layout } from '../components'

export default function DashboardPage() {
    return (
        <Layout>
            <Dashboard />
        </Layout>
    )
}
