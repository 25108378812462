import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layout, MyRfq } from '../components'
import { UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'
import { getCategoryAll } from '../utils/functions'

export default function MyRfqPage() {
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info
    const [rfq, setRfq] = useState()
    const [rfqCount, setRfqCount] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/rfq/user/${userInfo.id}?skip=0&limit=10`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        // Authorization: `Bearer ${token}`,
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    console.log('success')
                    setRfqCount(data[0].results)
                    setRfq(data[1])
                }
            } catch {}
        }
        fetchData()
    }, [])

    // const handleSubmit = async (e) => {}

    return (
        <Layout>
            <MyRfq rfq={rfq} rfqCount={rfqCount} />
        </Layout>
    )
}
