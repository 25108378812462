import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Layout, PageSettings } from '../components'
import { Auth, UserInfo } from '../contexts/allContext'
import { apiV1 } from '../utils/api'
import { fetchPageData } from '../utils/fetchPageData'
import { getCategoryAll } from '../utils/functions'

export default function PageSettingsPage() {
    // Auth
    const { auth } = useParams()
    const token = auth
    const { dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)
    const navigate = useNavigate()

    useEffect(() => {
        let userFetch = async () => {
            let logFetch = await fetch(`${apiV1}/auth/`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                dataType: 'json',
                method: 'GET',
            })

            let log = await logFetch.json()

            if (logFetch.ok) {
                dispatchAuth({ type: 'token', payload: token })
                dispatchUser({ type: 'set', payload: log })
            } else {
                dispatchAuth({ type: 'remove' })
                dispatchUser({ type: 'remove' })
            }
        }

        try {
            if (token) {
                userFetch()
            }
        } catch (e) {}
    }, [navigate, token])

    // Data
    const { stateUser } = useContext(UserInfo)
    const userInfo = stateUser.info

    const [pageName, setPageName] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [category, setCategory] = useState('')
    const [categoryOptions, setCategoryOptions] = useState('')
    const [about, setAbout] = useState('')
    const [pocName, setPocName] = useState('')
    const [pocPhone, setPocPhone] = useState('')
    const [designation, setDesignation] = useState('')

    const [registrationDate, setRegistrationDate] = useState('')
    const [yearsExport, setYearsExport] = useState('')
    const [annualExport, setAnnualExport] = useState('')
    const [floorSpace, setFloorSpace] = useState('')
    const [productionMachine, setProductionMachine] = useState('')
    const [productionLines, setProductionLines] = useState('')
    const [totalAnnualOutput, setTotalAnnualOutput] = useState('')
    const [supplyChain, setSupplyChain] = useState('')
    const [mainMarket, setMainMarket] = useState('')

    const [customization, setCustomization] = useState('')
    const [engineer, setEngineer] = useState('')

    const [imageLogo, setImageLogo] = useState(null)
    const [imageBanner, setImageBanner] = useState(null)

    const logoRef = useRef(null)
    const bannerRef = useRef(null)

    const handleLogoClick = () => {
        logoRef.current.click()
    }

    const handleBannerClick = () => {
        bannerRef.current.click()
    }

    const [imageAlert, setImageAlert] = useState(false)
    const handleLogoChange = (e) => {
        const file = e.target.files[0]

        if (file.size > 1 * 1000 * 1024) {
            setImageLogo(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImageLogo(file)
        }
    }

    const handleBannerChange = (e) => {
        const file = e.target.files[0]
        setImageBanner(file)
        if (file.size > 1 * 1000 * 1024) {
            setImageBanner(null)
            setImageAlert(true)
            setTimeout(function () {
                setImageAlert(false)
            }, 5000)
        } else {
            setImageBanner(file)
        }
    }

    const updateCompanyInformation = async () => {
        const details = {
            page_name: pageName,
            country: country,
            city: city,
            address: address,
            category: category,
            about: about,
            poc_name: pocName,
            poc_phone: pocPhone,
            designation: designation,
        }

        if (userInfo.id) {
            try {
                const response = await fetch(`${apiV1}/sellers/${userInfo.id}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updateBusinessInformation = async () => {
        const details = {
            registration_date: registrationDate,
            year_exporting: yearsExport,
            annual_export: annualExport,
            floor_space: floorSpace,
            production_machine: productionMachine,
            production_line: productionLines,
            annual_output: totalAnnualOutput,
            supply_partner: supplyChain,
            main_market: mainMarket,
        }

        if (userInfo.id) {
            try {
                const response = await fetch(`${apiV1}/companies/${userInfo.id}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const getPageData = () => {
        fetchPageData(
            apiV1,
            userInfo,
            setPageName,
            setCountry,
            setCity,
            setAddress,
            setCategory,
            setAbout,
            setPocName,
            setPocPhone,
            setDesignation,
            setRegistrationDate,
            setYearsExport,
            setAnnualExport,
            setFloorSpace,
            setProductionMachine,
            setProductionLines,
            setTotalAnnualOutput,
            setSupplyChain,
            setMainMarket
        )
    }

    const updateLogo = async () => {
        const logoData = new FormData()
        logoData.append('logo', imageLogo)
        if (userInfo.id) {
            try {
                const response = await fetch(`${apiV1}/logos/${userInfo.id}`, {
                    headers: {
                        Accept: 'application/json',
                        type: 'image/jpeg',
                        // Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: logoData,
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updateBanner = async () => {
        const bannerData = new FormData()
        bannerData.append('banner', imageBanner)

        if (userInfo.id) {
            try {
                const response = await fetch(`${apiV1}/banners/${userInfo.id}`, {
                    method: 'PUT',
                    headers: {
                        Accept: 'application/json',
                        type: 'image/jpeg',
                        // Authorization: `Bearer ${token}`,
                    },
                    body: bannerData,
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    useEffect(() => {
        fetchPageData(
            apiV1,
            userInfo,
            setPageName,
            setCountry,
            setCity,
            setAddress,
            setCategory,
            setAbout,
            setPocName,
            setPocPhone,
            setDesignation,
            setRegistrationDate,
            setYearsExport,
            setAnnualExport,
            setFloorSpace,
            setProductionMachine,
            setProductionLines,
            setTotalAnnualOutput,
            setSupplyChain,
            setMainMarket
        )
    }, [userInfo])

    useEffect(() => {
        // Function to fetch category data
        const fetchData = () => {
            getCategoryAll()
                .then((items) => {
                    setCategoryOptions(items)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error)
                })
        }

        fetchData()
    }, [])

    return (
        <Layout>
            <PageSettings
                userInfo={userInfo}
                pageName={pageName}
                country={country}
                city={city}
                address={address}
                categoryOptions={categoryOptions}
                category={category}
                about={about}
                pocName={pocName}
                pocPhone={pocPhone}
                designation={designation}
                setPageName={setPageName}
                setCountry={setCountry}
                setCity={setCity}
                setAddress={setAddress}
                setCategory={setCategory}
                setAbout={setAbout}
                setPocName={setPocName}
                setPocPhone={setPocPhone}
                setDesignation={setDesignation}
                updateCompanyInformation={updateCompanyInformation}
                registrationDate={registrationDate}
                yearsExport={yearsExport}
                annualExport={annualExport}
                floorSpace={floorSpace}
                productionMachine={productionMachine}
                productionLines={productionLines}
                totalAnnualOutput={totalAnnualOutput}
                supplyChain={supplyChain}
                mainMarket={mainMarket}
                customization={customization}
                engineer={engineer}
                setRegistrationDate={setRegistrationDate}
                setYearsExport={setYearsExport}
                setAnnualExport={setAnnualExport}
                setFloorSpace={setFloorSpace}
                setProductionMachine={setProductionMachine}
                setProductionLines={setProductionLines}
                setTotalAnnualOutput={setTotalAnnualOutput}
                setSupplyChain={setSupplyChain}
                setMainMarket={setMainMarket}
                setCustomization={setCustomization}
                setEngineer={setEngineer}
                updateBusinessInformation={updateBusinessInformation}
                getPageData={getPageData}
                updateLogo={updateLogo}
                updateBanner={updateBanner}
                imageLogo={imageLogo}
                setImageLogo={setImageLogo}
                imageBanner={imageBanner}
                setImageBanner={setImageBanner}
                handleLogoClick={handleLogoClick}
                handleBannerClick={handleBannerClick}
                handleLogoChange={handleLogoChange}
                handleBannerChange={handleBannerChange}
                logoRef={logoRef}
                bannerRef={bannerRef}
                imageAlert={imageAlert}
            />
        </Layout>
    )
}
