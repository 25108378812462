import React from 'react'
import { Body, ComingSoon } from '../Resource'

export default function Orders() {
    return (
        <Body>
            <ComingSoon title="Orders" />
        </Body>
    )
}
