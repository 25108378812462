export const fetchPageData = async (
    apiV1,
    userInfo,
    setPageName,
    setCountry,
    setCity,
    setAddress,
    setCategory,
    setAbout,
    setPocName,
    setPocPhone,
    setDesignation,
    setRegistrationDate,
    setYearsExport,
    setAnnualExport,
    setFloorSpace,
    setProductionMachine,
    setProductionLines,
    setTotalAnnualOutput,
    setSupplyChain,
    setMainMarket
) => {
    if (userInfo.id) {
        try {
            const responseCompany = await fetch(`${apiV1}/sellers/${userInfo.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            })

            const responseBusiness = await fetch(`${apiV1}/companies/${userInfo.id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            })

            const data_Company = await responseCompany.json()
            const dataCompany = await data_Company.Seller
            const data_Business = await responseBusiness.json()
            const dataBusiness = await data_Business.Company

            if (responseCompany.ok) {
                // console.log('success company')
                setPageName(dataCompany.page_name)
                setCountry(dataCompany.country)
                setCity(dataCompany.city)
                setAddress(dataCompany.address)
                setCategory(dataCompany.category)
                setAbout(dataCompany.about)
                setPocName(dataCompany.poc_name)
                setPocPhone(dataCompany.poc_phone)
                setDesignation(dataCompany.designation)
            }

            if (responseBusiness.ok) {
                // console.log('success business')
                setRegistrationDate(dataBusiness.registration_date)
                setYearsExport(dataBusiness.year_exporting)
                setAnnualExport(dataBusiness.annual_export)
                setFloorSpace(dataBusiness.floor_space)
                setProductionMachine(dataBusiness.production_machine)
                setProductionLines(dataBusiness.production_line)
                setTotalAnnualOutput(dataBusiness.annual_output)
                setSupplyChain(dataBusiness.supply_partner)
                setMainMarket(dataBusiness.main_market)
            }
        } catch {}
    }
}
