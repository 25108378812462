import React from 'react'
import Loader from 'react-spinners/BeatLoader'
import { Body } from '../Resource'
import classes from './AuthRoute.module.css'

export default function AuthRoute() {
    return (
        <Body>
            <div className={classes.loader}>
                <div>Please wait for some moment</div>
                <Loader width={'100%'} color="#ff7f50" />
            </div>
        </Body>
    )
}
