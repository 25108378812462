import React from 'react'
import { Layout, TradeService } from '../components'

export default function TradeServicePage() {
    return (
        <Layout>
            <TradeService />
        </Layout>
    )
}
