import React, { useContext, useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { BsChatDots } from 'react-icons/bs'
import { FaClipboardList } from 'react-icons/fa'
import { IoMdChatbubbles } from 'react-icons/io'
import { IoLogoWhatsapp } from 'react-icons/io'
import { IoMdCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { MdDashboard } from 'react-icons/md'
import { MdDashboardCustomize } from 'react-icons/md'
import { RiCustomerService2Fill } from 'react-icons/ri'
import { SiHomeassistantcommunitystore } from 'react-icons/si'
import { TbDeviceAnalytics } from 'react-icons/tb'
import { TiContacts } from 'react-icons/ti'
import { Link, useNavigate } from 'react-router-dom'
import { Sidebar, Navbar } from '../../components'
import { SidebarContext } from '../../contexts/sidebarContext'
import { apiV1 } from '../../utils/api'
import classes from './Layout.module.css'

export default function Layout({ children }) {
    const { isOpenSidebar } = useContext(SidebarContext)
    const [clickOn, setClickOn] = useState(false)
    const navigate = useNavigate('')

    const sidebarItems = [
        {
            path: '/dashboard',
            icon: <MdDashboard className={classes.iconStyle} />,
            text: 'Dashboard',
        },
        // {
        //     path: '/page-settings',
        //     icon: <SiHomeassistantcommunitystore className={classes.iconStyle} />,
        //     text: 'Page Settings',
        // },

        // {
        //     path: '/products',
        //     icon: <MdDashboardCustomize className={classes.iconStyle} />,
        //     text: 'Products',
        // },
        {
            path: '/inquiries',
            icon: <TbDeviceAnalytics className={classes.iconStyle} />,
            text: 'My Inquiries',
        },
        {
            path: '/my-rfq',
            icon: <TbDeviceAnalytics className={classes.iconStyle} />,
            text: 'My RFQ',
        },
        {
            path: '/post-rfq',
            icon: <TbDeviceAnalytics className={classes.iconStyle} />,
            text: 'Post RFQ',
        },
        {
            path: '/orders',
            icon: <TbDeviceAnalytics className={classes.iconStyle} />,
            text: 'Orders',
        },
        {
            path: '/contacts',
            icon: <TiContacts className={classes.iconStyle} />,
            text: 'Contacts',
        },
        {
            path: '/trade-service',
            icon: <BsChatDots className={classes.iconStyle} />,
            text: 'TradeService',
        },
        {
            path: '/logistics',
            icon: <FaClipboardList className={classes.iconStyle} />,
            text: 'Logistics',
        },

        // {
        //     path: '/help-center',
        //     icon: <RiCustomerService2Fill className={classes.iconStyle} />,
        //     text: 'Help Center',
        // },
        // {
        //     path: '/settings',
        //     icon: <FiSettings className={classes.iconStyle} />,
        //     text: 'Settings',
        // },
    ]

    return (
        <div className={classes.layout}>
            <div>
                <Sidebar sidebarItems={sidebarItems} />
            </div>
            <div>
                <Navbar />
                <Sidebar sidebarItems={sidebarItems} />
                {children}
            </div>
            <div onClick={() => (!clickOn ? setClickOn(true) : setClickOn(false))} className={classes.support}>
                <div>
                    {!clickOn ? (
                        <IoMdChatbubbles className={classes.iconSupport} />
                    ) : (
                        <AiOutlineClose className={classes.iconSupport} />
                    )}
                </div>
                Need help!
            </div>
            {clickOn && (
                <div className={classes.supportDetails}>
                    <div>Customer Support</div>
                    <div>
                        Contact: <span style={{ fontWeight: '500' }}>+880-1727611963</span>
                    </div>
                    <div>
                        Address: <span style={{ fontWeight: '500' }}>Road 2, Banani, Dhaka-1212</span>
                    </div>
                    <div>
                        <div>
                            <a href="tel:+880-1727611963">
                                <IoMdCall className={classes.iconSocial} />
                                Call
                            </a>
                        </div>
                        <div>
                            <a href="https://wa.me/8801727611963">
                                <IoLogoWhatsapp className={classes.iconSocial} />
                                Whatsapp
                            </a>
                        </div>
                        <div>
                            <a href="mailto:contact@biztradebd.com">
                                <MdEmail className={classes.iconSocial} />
                                Email
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
