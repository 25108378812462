import React, { useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import { apiV1 } from '../../../utils/api'
import classes from './ProductDetails.module.css'

export default function ProductDetails(props) {
    const [product, setProduct] = useState('')
    const [attribute, setAttribute] = useState('')
    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [subSubCategory, setSubSubCategory] = useState('')
    const [packages, setPackages] = useState('')
    const [price, setPrice] = useState('')
    const [image, setImage] = useState('')

    useEffect(() => {
        const fetchProduct = () => {
            if (props.product) {
                setProduct(props.product.Product)
                setAttribute(props.product.Attribute)
                setCategory(props.product.Category)
                setSubCategory(props.product.SubCategory)
                setSubSubCategory(props.product.SubSubCategory)
                setPackages(props.product.Package)
                setPrice(props.product.Price)
                setImage(props.product.ImgProduct)
            }
        }
        fetchProduct()
    }, [props.product])
    const mainImage =
        props.product && props.product.ImgProduct.image_string
            ? `${apiV1}/images/product/${props.product.ImgProduct.image_string}`
            : ''

    const image2 =
        props.product && props.product.ImgProduct.image_string2
            ? `${apiV1}/images/product/${props.product.ImgProduct.image_string2}`
            : ''

    const image3 =
        props.product && props.product.ImgProduct.image_string3
            ? `${apiV1}/images/product/${props.product.ImgProduct.image_string3}`
            : ''
    const image4 =
        props.product && props.product.ImgProduct.image_string4
            ? `${apiV1}/images/product/${props.product.ImgProduct.image_string4}`
            : ''

    const image5 =
        props.product && props.product.ImgProduct.image_string5
            ? `${apiV1}/images/product/${props.product.ImgProduct.image_string5}`
            : ''

    return (
        <div className={classes.wrapper}>
            <div>
                <div className={props.product && image.image_string ? classes.mainImage : classes.mainImageEmpty}>
                    <img src={mainImage} alt="" />
                </div>
                <div>
                    <p>{product.title}</p>
                    <div>
                        <p>
                            {price.currency === 'dollar'
                                ? '$'
                                : price.currency === 'taka'
                                ? '৳'
                                : price.currency === 'euro'
                                ? '€'
                                : '$'}
                            {price.price}
                        </p>
                        <p>Quantity: {product.quantity}</p>
                        <p>SKU: {product.sku}</p>
                        <p>Category: {category.name}</p>
                        <p>Size: {attribute.value}</p>
                        <p>
                            Weight: {packages.weight}
                            {packages.weight_unit}
                        </p>
                    </div>
                </div>
            </div>
            <div
                onClick={() => {
                    props.setCurrentCategory(category.name)
                    props.setCurrentSubCategory(subCategory.name)
                    props.setCurrentSubSubCategory(subSubCategory.name)
                    props.setCurrentImg1(mainImage)
                    props.setCurrentImg2(image2)
                    props.setCurrentImg3(image3)
                    props.setCurrentImg4(image4)
                    props.setCurrentImg5(image5)
                    props.handleProductEditPopUp()
                    props.setProductId(product.id)
                    props.setProduct((prev) => {
                        return {
                            ...prev,
                            title: product.title,
                            description: product.description,
                            quantity: product.quantity,
                            sku: product.sku,
                        }
                    })
                    props.setPrice((prev) => {
                        return {
                            ...prev,
                            price: price.price,
                            currency: price.currency,
                            moq: price.moq,
                            discount: price.discount,
                            moq2: price.moq2,
                            discount2: price.discount2,
                            moq3: price.moq3,
                            discount3: price.discount3,
                        }
                    })

                    props.setPackages((prev) => {
                        return {
                            ...prev,
                            weight: packages.weight,
                            weight_unit: packages.weight_unit,
                            length: packages.length,
                            width: packages.width,
                            height: packages.height,
                            measurement_unit: packages.measurement_unit,
                        }
                    })

                    props.setAttributes((prev) => {
                        return {
                            ...prev,
                            name: attribute.name,
                            value: attribute.value,
                            name2: attribute.name2,
                            value2: attribute.value2,
                            name3: attribute.name3,
                            value3: attribute.value3,
                            name4: attribute.name4,
                            value4: attribute.value4,
                            name5: attribute.name5,
                            value5: attribute.value5,
                            name6: attribute.name6,
                            value6: attribute.value6,
                            name7: attribute.name7,
                            value7: attribute.value7,
                            name8: attribute.name8,
                            value8: attribute.value8,
                            name9: attribute.name9,
                            value9: attribute.value9,
                        }
                    })
                }}>
                <FiEdit className={classes.iconStyle} />

                <div className={classes.edit}>Edit</div>
            </div>
        </div>
    )
}
