import React from 'react'
import { BiImageAdd } from 'react-icons/bi'
import classes from './ImageUpload.module.css'

export default function ImageUpload(props) {
    return (
        <>
            <div className={classes.wrapper}>
                <p>{props.label}</p>
                <div
                    className={props.imageshow === 'display' ? classes.wrapperImage : classes.wrapperImageSmall}
                    onClick={props.handleClick}>
                    {props.image ? (
                        <>
                            <img src={URL.createObjectURL(props.image)} alt="" />
                        </>
                    ) : (
                        <>
                            {/* <BiImageAdd className={classes.iconStyle} />
                            <p>Click to upload the image</p>
                            <p>PNG, JPG (max 500 x 500px)</p> */}

                            <img src={props.currentImage ? props.currentImage : ''} alt="" />
                        </>
                    )}
                    <input
                        type="file"
                        disabled={props.inputDisable}
                        onChange={props.handleChange}
                        ref={props.imgRef}
                        style={{ display: 'none' }}
                    />
                </div>

                {props.image ? (
                    <div>
                        <div>Click on image to change</div>
                        <div onClick={() => props.setImage(null)}>Clear</div>
                    </div>
                ) : (
                    ''
                )}

                {/* <p>Display Image</p>
            <div className={classes.wrapperImage} onClick={props.handleDisplayClick}>
                {props.displayImage ? (
                    <>
                        <img src={URL.createObjectURL(props.displayImage)} alt="" />
                    </>
                ) : (
                    <>
                        <p>Click to upload display image</p>
                        <p>PNG, JPG (max 500 x 500px)</p>
                    </>
                )}
                <input
                    type="file"
                    disabled={false}
                    onChange={props.handleDisplayChange}
                    ref={props.imgRef}
                    style={{ display: 'none' }}
                />
            </div> */}
            </div>
        </>
    )
}
