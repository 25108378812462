import React, { useContext, useEffect, useState } from 'react'
import { BiHelpCircle } from 'react-icons/bi'
import { FaUserAlt } from 'react-icons/fa'
import { MdNotificationsActive } from 'react-icons/md'
import { MdDashboardCustomize } from 'react-icons/md'
import { MdArrowDropDown } from 'react-icons/md'
import { Link } from 'react-router-dom'
import logo from '../../../assets/biztrade_logo/logo.png'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { apiV1, buyerUrl, homeUrl, sellerUrl } from '../../../utils/api'
import classes from './Navbar.module.css'

export default function Navbar() {
    const { stateUser } = useContext(UserInfo)
    const { stateAuth } = useContext(Auth)
    const userInfo = stateUser.info
    const token = stateAuth.token
    const [companyLogo, setCompanyLogo] = useState(null)
    const [toggle, setToggle] = useState(true)

    // useEffect(() => {
    //     if (userInfo.role_name === 'seller') {
    //         setToggle(true)
    //     } else if (userInfo.role_name === 'buyer') {
    //         setToggle(true)
    //     }
    // }, [userInfo.role_name])

    const handleSeller = async () => {
        if (userInfo.role_name === 'seller') {
            setToggle(false)
            window.location.href = `${sellerUrl}/${token}`
        } else {
            alert('Your are not authorized to go seller dashboard. Please contact us for more information.')
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (userInfo.id) {
                try {
                    const response = await fetch(`${apiV1}/companies/${userInfo.id}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            // Authorization: `Bearer ${token}`,
                        },
                    })

                    const data = await response.json()
                    const logoData = data.ImgLogo.image_string

                    if (response.ok) {
                        if (logoData) {
                            setCompanyLogo(`${apiV1}/images/logo/${logoData}`)
                        }
                    }
                } catch {}
            }
        }
        fetchData()
    }, [userInfo.id])

    return (
        <div className={classes.navbar}>
            <Link to={`${homeUrl}`}>
                <div className={classes.navbarLeft}>
                    <img src={logo} alt="" />
                </div>
            </Link>

            <div className={classes.navbarRight}>
                <div className={classes.switch}>
                    <div className={toggle ? classes.active : classes.inactive}>Buyer</div>
                    <div
                        onClick={() => (toggle ? handleSeller() : '')}
                        className={!toggle ? classes.active : classes.inactive}>
                        Seller
                    </div>
                </div>
                <div className={classes.sectiontab}>
                    <div>
                        <BiHelpCircle className={classes.iconStyle} />
                    </div>
                    <div className={classes.notification} current-count="0">
                        <MdNotificationsActive className={classes.iconStyle} />
                    </div>
                    <div>
                        <MdDashboardCustomize className={classes.iconStyle} />
                    </div>
                </div>
                <div className={classes.profile}>
                    <div>
                        {companyLogo ? <img src={companyLogo} alt="" /> : <FaUserAlt className={classes.iconUser} />}
                    </div>
                    <button>
                        <div className={classes.button1}>
                            <p>{userInfo.name}</p>
                        </div>

                        <div className={classes.button2}>
                            <p>{userInfo.name ? userInfo.name.split(' ')[0] : userInfo.name}</p>
                        </div>
                        <div>
                            <MdArrowDropDown className={classes.iconDropdown} />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}
