import { useReducer } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Auth, UserInfo } from './contexts/allContext'
import { authReducer, authState } from './contexts/reducer/authReducer'
import { userReducer, userState } from './contexts/reducer/userReducer'
import {
    AnalysisPage,
    AuthPage,
    CommunicationPage,
    ContactsPage,
    DashboardPage,
    HelpCenterPage,
    LoginPage,
    LogisticPage,
    MyRfqPage,
    OrdersPage,
    PageSettingsPage,
    ProductAddPage,
    ProductListPage,
    RfqPostPage,
    SettingsPage,
    TradeServicePage,
} from './pages'
import InquiriesPage from './pages/Inquiries'
// import RfqPage from './pages/Rfq'
import ProtectedRoute from './routes/ProtectedRoute'

function App() {
    const [stateAuth, dispatchAuth] = useReducer(authReducer, authState)
    const [stateUser, dispatchUser] = useReducer(userReducer, userState)

    return (
        <Auth.Provider value={{ stateAuth, dispatchAuth }}>
            <UserInfo.Provider value={{ stateUser, dispatchUser }}>
                <Routes>
                    {/* <Route path="" element={<Fetch title={'Github Info from Props'} />} /> */}
                    <Route path="" element={<LoginPage />} />
                    <Route path="/auth/:auth" element={<AuthPage />} />
                    <Route path="/*" element={<ProtectedRoute />}>
                        <Route path="dashboard" element={<DashboardPage />} />
                        <Route path="page-settings" element={<PageSettingsPage />} />
                        <Route path="product-list" element={<ProductListPage />} />
                        <Route path="product-add" element={<ProductAddPage />} />
                        <Route path="trade-service" element={<TradeServicePage />} />
                        <Route path="orders" element={<OrdersPage />} />
                        <Route path="logistics" element={<LogisticPage />} />
                        <Route path="contacts" element={<ContactsPage />} />
                        <Route path="inquiries" element={<InquiriesPage />} />
                        <Route path="post-rfq" element={<RfqPostPage />} />
                        <Route path="my-rfq" element={<MyRfqPage />} />
                    </Route>
                </Routes>
            </UserInfo.Provider>
        </Auth.Provider>
    )
}

export default App
