import React from 'react'
import { Body, ComingSoon } from '../Resource'

export default function Contacts() {
    return (
        <Body>
            <ComingSoon title="Contact" />
        </Body>
    )
}
