import React from 'react'
import { Contacts, Layout } from '../components'

export default function ContactsPage() {
    return (
        <Layout>
            <Contacts />
        </Layout>
    )
}
