import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PopUp, ProductDetails } from '../Resource'
import classes from './ProductList.module.css'

export default function ProductList(props) {
    const [currentCategory, setCurrentCategory] = useState('')
    const [currentSubCategory, setCurrentSubCategory] = useState('')
    const [currentSubSubCategory, setCurrentSubSubCategory] = useState('')
    const navigate = useNavigate()
    return (
        <>
            <PopUp
                btnClickPopUp={props.btnClickPopUp}
                activePopUp={props.activePopUp}
                setActivePopUp={props.setActivePopUp}
                // category edit
                category={props.category}
                subCategory={props.subCategory}
                subSubCategory={props.subSubCategory}
                setCategory={props.setCategory}
                setSubCategory={props.setSubCategory}
                setSubSubCategory={props.setSubSubCategory}
                categoryOption={props.categoryOption}
                subCategoryOption={props.subCategoryOption}
                subSubCategoryOption={props.subSubCategoryOption}
                currentCategory={currentCategory}
                currentSubCategory={currentSubCategory}
                currentSubSubCategory={currentSubSubCategory}
                updateCategory={props.updateCategory}
                // product edit
                productId={props.productId}
                product={props.product}
                setProduct={props.setProduct}
                price={props.price}
                setPrice={props.setPrice}
                currency={props.currency}
                setCurrency={props.setCurrency}
                currencyOptions={props.currencyOptions}
                packages={props.packages}
                setPackages={props.setPackages}
                weightUnit={props.weightUnit}
                setWeightUnit={props.setWeightUnit}
                measurementUnit={props.measurementUnit}
                setMeasurmentUnit={props.setMeasurmentUnit}
                measurementUnitOptions={props.measurementUnitOptions}
                weightUnitOptions={props.weightUnitOptions}
                attribute={props.attribute}
                setAttribute={props.setAttribute}
                attributes={props.attributes}
                setAttributes={props.setAttributes}
                handleAddClick={props.handleAddClick}
                handleRemoveClick={props.handleRemoveClick}
                handleInputChange={props.handleInputChange}
                updateProduct={props.updateProduct}
                updatePrice={props.updatePrice}
                updatePackage={props.updatePackage}
                updateAttribute={props.updateAttribute}
                updateImage={props.updateImage}
                // image edit
                currentImg1={props.currentImg1}
                currentImg2={props.currentImg2}
                currentImg3={props.currentImg3}
                currentImg4={props.currentImg4}
                currentImg5={props.currentImg5}
                displayImage={props.displayImage}
                image2={props.image2}
                image3={props.image3}
                image4={props.image4}
                image5={props.image5}
                setDisplayImage={props.setDisplayImage}
                setImage2={props.setImage2}
                setImage3={props.setImage3}
                setImage4={props.setImage4}
                setImage5={props.setImage5}
                displayImageRef={props.displayImageRef}
                image2Ref={props.image2Ref}
                image3Ref={props.image3Ref}
                image4Ref={props.image4Ref}
                image5Ref={props.image5Ref}
                handleDisplayClick={props.handleDisplayClick}
                handleImg2Click={props.handleImg2Click}
                handleImg3Click={props.handleImg3Click}
                handleImg4Click={props.handleImg4Click}
                handleImg5Click={props.handleImg5Click}
                handleDisplayChange={props.handleDisplayChange}
                handleImage2Change={props.handleImage2Change}
                handleImage3Change={props.handleImage3Change}
                handleImage4Change={props.handleImage4Change}
                handleImage5Change={props.handleImage5Change}
                updated={props.updated}
            />
            <div className={classes.wrapper}>
                <div>
                    <div className={classes.search}>
                        <p>Product List</p>
                        {/* <div>
                        <p>Search</p>
                        <div>
                            <input type="text" placeholder="Search by title or sku" />
                        </div>
                    </div> */}
                    </div>
                    <div>
                        {/* <div>
                        <p>Drafts(0)</p>
                        <p>Active({props.product.length})</p>
                    </div> */}
                        <div>
                            <p>Viewing all items ({props.countData})</p>
                            <div onClick={() => navigate('/product-add')}>+ Add Product</div>
                            {/* <p>Date Created (Newest)</p> */}
                        </div>
                        <div className={classes.productList}>
                            {props.products &&
                                props.products.map((item, sl) => (
                                    <span key={sl}>
                                        <ProductDetails
                                            handleProductEditPopUp={props.handleProductEditPopUp}
                                            setProductId={props.setProductId}
                                            setProduct={props.setProduct}
                                            setPrice={props.setPrice}
                                            setPackages={props.setPackages}
                                            weightUnit={props.weightUnit}
                                            setWeightUnit={props.setWeightUnit}
                                            measurementUnit={props.measurementUnit}
                                            setMeasurmentUnit={props.setMeasurmentUnit}
                                            currencyOptions={props.currencyOptions}
                                            setAttributes={props.setAttributes}
                                            product={item}
                                            // image edit
                                            setCurrentImg1={props.setCurrentImg1}
                                            setCurrentImg2={props.setCurrentImg2}
                                            setCurrentImg3={props.setCurrentImg3}
                                            setCurrentImg4={props.setCurrentImg4}
                                            setCurrentImg5={props.setCurrentImg5}
                                            // category
                                            setCurrentCategory={setCurrentCategory}
                                            setCurrentSubCategory={setCurrentSubCategory}
                                            setCurrentSubSubCategory={setCurrentSubSubCategory}
                                        />
                                    </span>
                                ))}
                        </div>
                        <div className={classes.paginationButton}>
                            <div>
                                <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>
                                    {props.slNo && Math.ceil(props.slNo / 10)}
                                </span>
                                /{props.countData && Math.ceil(props.countData / 10)}
                            </div>
                            <button
                                disabled={props.slNo === 1 && true}
                                type="button"
                                onClick={() => {
                                    props.handlePrevious()
                                }}>
                                {'<< '}Previous
                            </button>
                            <button
                                disabled={Math.ceil(props.slNo / 10) === Math.ceil(props.countData / 10) ? true : false}
                                type="button"
                                onClick={() => props.handleNext()}>
                                Next {' >>'}
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Overview</p>
                        <div>
                            <p>Total product: {props.countData}</p>
                            <p>Orders: 0</p>
                            <p>Invoices: 0</p>
                        </div>
                    </div>
                    <div>Others</div>
                </div>
            </div>
        </>
    )
}
