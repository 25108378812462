import { apiV1 } from './api'

export async function getCategoryAll() {
    try {
        const response = await fetch(`${apiV1}/categories/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()
        const categoryOptions = []

        if (response.ok) {
            await data.map((e) => categoryOptions.push({ id: e.id, label: e.name, value: e.name }))
            return categoryOptions
        }
    } catch {}
}

export async function getSellerById(userId) {
    try {
        const response = await fetch(`${apiV1}/sellers/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}

export async function getBuyerById(userId) {
    try {
        const response = await fetch(`${apiV1}/buyers/${userId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            return data
        }
    } catch {}
}
