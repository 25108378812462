import React from 'react'
import classes from './PopUp.module.css'
import ProductEdit from './ProductEdit/ProductEdit'
import ProductImageUploadPopUp from './ProductImageUploadPopUp/ProductImageUploadPopUp'
import ProductUpload from './ProductUpload/ProductUpload'
import RfqUpload from './RfqUpload/RfqUpload'

export default function PopUp(props) {
    const { btnClickPopUp, activePopUp, setActivePopUp, payment } = props

    if (activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }
    return (
        <>
            {activePopUp && (
                <>
                    <div className={classes.model}>
                        <div className={classes.overlay}></div>{' '}
                        {btnClickPopUp === '1' && (
                            <ProductUpload activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                        {btnClickPopUp === '2' && (
                            <ProductImageUploadPopUp activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                        {btnClickPopUp === '3' && (
                            <ProductEdit
                                activePopUp={activePopUp}
                                setActivePopUp={setActivePopUp}
                                product={props.product}
                                setProduct={props.setProduct}
                                // category edit
                                category={props.category}
                                subCategory={props.subCategory}
                                subSubCategory={props.subSubCategory}
                                setCategory={props.setCategory}
                                setSubCategory={props.setSubCategory}
                                setSubSubCategory={props.setSubSubCategory}
                                categoryOption={props.categoryOption}
                                subCategoryOption={props.subCategoryOption}
                                subSubCategoryOption={props.subSubCategoryOption}
                                currentCategory={props.currentCategory}
                                currentSubCategory={props.currentSubCategory}
                                currentSubSubCategory={props.currentSubSubCategory}
                                updateCategory={props.updateCategory}
                                // product edit
                                productId={props.productId}
                                price={props.price}
                                setPrice={props.setPrice}
                                currency={props.currency}
                                setCurrency={props.setCurrency}
                                currencyOptions={props.currencyOptions}
                                packages={props.packages}
                                setPackages={props.setPackages}
                                weightUnit={props.weightUnit}
                                setWeightUnit={props.setWeightUnit}
                                measurementUnit={props.measurementUnit}
                                setMeasurmentUnit={props.setMeasurmentUnit}
                                measurementUnitOptions={props.measurementUnitOptions}
                                weightUnitOptions={props.weightUnitOptions}
                                attribute={props.attribute}
                                setAttribute={props.setAttribute}
                                attributes={props.attributes}
                                setAttributes={props.setAttributes}
                                handleAddClick={props.handleAddClick}
                                handleRemoveClick={props.handleRemoveClick}
                                handleInputChange={props.handleInputChange}
                                // image edit
                                currentImg1={props.currentImg1}
                                currentImg2={props.currentImg2}
                                currentImg3={props.currentImg3}
                                currentImg4={props.currentImg4}
                                currentImg5={props.currentImg5}
                                displayImage={props.displayImage}
                                image2={props.image2}
                                image3={props.image3}
                                image4={props.image4}
                                image5={props.image5}
                                setDisplayImage={props.setDisplayImage}
                                setImage2={props.setImage2}
                                setImage3={props.setImage3}
                                setImage4={props.setImage4}
                                setImage5={props.setImage5}
                                displayImageRef={props.displayImageRef}
                                image2Ref={props.image2Ref}
                                image3Ref={props.image3Ref}
                                image4Ref={props.image4Ref}
                                image5Ref={props.image5Ref}
                                handleDisplayClick={props.handleDisplayClick}
                                handleImg2Click={props.handleImg2Click}
                                handleImg3Click={props.handleImg3Click}
                                handleImg4Click={props.handleImg4Click}
                                handleImg5Click={props.handleImg5Click}
                                handleDisplayChange={props.handleDisplayChange}
                                handleImage2Change={props.handleImage2Change}
                                handleImage3Change={props.handleImage3Change}
                                handleImage4Change={props.handleImage4Change}
                                handleImage5Change={props.handleImage5Change}
                                // update
                                updateProduct={props.updateProduct}
                                updatePrice={props.updatePrice}
                                updatePackage={props.updatePackage}
                                updateAttribute={props.updateAttribute}
                                updateImage={props.updateImage}
                                updated={props.updated}
                            />
                        )}
                        {btnClickPopUp === '4' && (
                            <RfqUpload activePopUp={activePopUp} setActivePopUp={setActivePopUp} />
                        )}
                    </div>
                </>
            )}
        </>
    )
}
