import React from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import classes from './PhoneInputField.module.css'

export default function PhoneInputField({ children, placeholder, defaultCountry, setData, data, required }) {
    return (
        <PhoneInput
            className={data ? (isValidPhoneNumber(data) ? classes.phoneInput : classes.invalid) : classes.phoneInput}
            international
            defaultCountry="BD"
            placeholder={placeholder}
            value={data}
            onChange={setData}
            error={data ? (isValidPhoneNumber(data) ? undefined : 'Invalid phone number') : 'Phone number required'}
        />
    )
}
