import React, { useRef } from 'react'
import { Body, ImageUpload, InputField, MoqInputField, PopUp, SubmitButton } from '../Resource'
import DoubleInputField from '../Resource/DoubleInputField/DoubleInputField'
import { SelectField } from '../Resource/SelectField/SelectField'
import classes from './ProductAdd.module.css'

export default function ProductAdd(props) {
    return (
        <Body>
            <div className={classes.wrapper}>
                <PopUp
                    btnClickPopUp={props.btnClickPopUp}
                    activePopUp={props.activePopUp}
                    setActivePopUp={props.setActivePopUp}
                />
                <form onSubmit={(e) => props.handleSubmit(e)}>
                    <div>
                        <p>Product Add</p>
                    </div>
                    <div>
                        <div className={classes.inputMessage}>
                            <SelectField
                                search_by="-- Select category --"
                                searchable={true}
                                clearable={true}
                                options={props.categoryOption}
                                value={props.category}
                                selectOnChange={(e) => {
                                    if (e) {
                                        props.setCategory(e)
                                        props.setSubCategory('')
                                    } else {
                                        props.setCategory('')
                                        props.setSubCategory('')
                                    }
                                }}
                            />
                            <input
                                value={props.category ? props.category.id : ''}
                                onChange={(e) => e.target.value}
                                required
                            />
                        </div>

                        {props.category && (
                            <div className={classes.inputMessage}>
                                <SelectField
                                    search_by="-- Select sub category --"
                                    searchable={true}
                                    clearable={true}
                                    options={props.subCategoryOption}
                                    value={props.subCategory}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setSubCategory(e)
                                        } else {
                                            props.setSubCategory('')
                                        }
                                    }}
                                />
                                <input
                                    value={props.subCategory ? props.subCategory.id : ''}
                                    onChange={(e) => e.target.value}
                                    required
                                />
                            </div>
                        )}

                        {props.category && props.subCategory && (
                            <div className={classes.inputMessage}>
                                <SelectField
                                    search_by="-- Select sub sub category --"
                                    searchable={true}
                                    clearable={true}
                                    options={props.subSubCategoryOption}
                                    value={props.subSubCategory}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setSubSubCategory(e)
                                        } else {
                                            props.setSubSubCategory('')
                                        }
                                    }}
                                />
                                <input
                                    value={props.subSubCategory ? props.subSubCategory.id : ''}
                                    onChange={(e) => e.target.value}
                                    required
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <div>
                            <p>Description</p>
                            <div>
                                <InputField
                                    label="Product Name"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.product.title}
                                    onChange={(e) =>
                                        e
                                            ? props.setProduct((prev) => {
                                                  return { ...prev, title: e.target.value }
                                              })
                                            : props.setProduct((prev) => {
                                                  return { ...prev, title: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="Product Short Description"
                                    disabled={false}
                                    required={false}
                                    type="textArea"
                                    value={props.product.description}
                                    onChange={(e) =>
                                        e
                                            ? props.setProduct((prev) => {
                                                  return { ...prev, description: e.target.value }
                                              })
                                            : props.setProduct((prev) => {
                                                  return { ...prev, description: null }
                                              })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <p>Specification</p>
                            <div>
                                <InputField
                                    label="Product Size"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.attributes.value}
                                    onChange={(e) =>
                                        e
                                            ? props.setAttributes((prev) => {
                                                  return { ...prev, value: e.target.value }
                                              })
                                            : props.setAttributes((prev) => {
                                                  return { ...prev, value: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="Material"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.attributes.value2}
                                    onChange={(e) =>
                                        e
                                            ? props.setAttributes((prev) => {
                                                  return { ...prev, value2: e.target.value }
                                              })
                                            : props.setAttributes((prev) => {
                                                  return { ...prev, value2: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="Color"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.attributes.value3}
                                    onChange={(e) =>
                                        e
                                            ? props.setAttributes((prev) => {
                                                  return { ...prev, value3: e.target.value }
                                              })
                                            : props.setAttributes((prev) => {
                                                  return { ...prev, value3: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="Supply Ability"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.attributes.value4}
                                    onChange={(e) =>
                                        e
                                            ? props.setAttributes((prev) => {
                                                  return { ...prev, value4: e.target.value }
                                              })
                                            : props.setAttributes((prev) => {
                                                  return { ...prev, value4: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="Delivery Time"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.attributes.value5}
                                    onChange={(e) =>
                                        e
                                            ? props.setAttributes((prev) => {
                                                  return { ...prev, value5: e.target.value }
                                              })
                                            : props.setAttributes((prev) => {
                                                  return { ...prev, value5: null }
                                              })
                                    }
                                />
                                {props.attribute.map((data, index) => (
                                    <div key={index}>
                                        <DoubleInputField
                                            disabled={false}
                                            required={false}
                                            type1="text"
                                            type2="text"
                                            name1="label"
                                            name2="value"
                                            placeholder1="Atrribute name"
                                            placeholder2="Attribute value"
                                            value1={data.label}
                                            value2={data.value}
                                            onChange1={(e) => props.handleInputChange(e, index)}
                                            onChange2={(e) => props.handleInputChange(e, index)}
                                            handleRemoveClick={props.handleRemoveClick}
                                            index={index}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button
                                className={classes.btnAdd}
                                type="button"
                                disabled={props.attribute.length > 4 ? true : false}
                                onClick={props.handleAddClick}>
                                + Add more attributes
                            </button>
                        </div>
                        <div>
                            <p>Inventory</p>
                            <div>
                                <InputField
                                    label="Quantity"
                                    disabled={false}
                                    required={true}
                                    type="text"
                                    value={props.product.quantity}
                                    onChange={(e) =>
                                        e
                                            ? props.setProduct((prev) => {
                                                  return { ...prev, quantity: e.target.value }
                                              })
                                            : props.setProduct((prev) => {
                                                  return { ...prev, quantity: null }
                                              })
                                    }
                                />
                                <InputField
                                    label="SKU"
                                    disabled={false}
                                    required={true}
                                    type="text"
                                    value={props.product.sku}
                                    onChange={(e) =>
                                        e
                                            ? props.setProduct((prev) => {
                                                  return { ...prev, sku: e.target.value }
                                              })
                                            : props.setProduct((prev) => {
                                                  return { ...prev, sku: null }
                                              })
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <p>Pricing</p>
                            <div>
                                <div className={classes.pricingField}>
                                    <InputField
                                        label="Regular Price"
                                        disabled={false}
                                        required={true}
                                        type="text"
                                        inputUnit={true}
                                        unit={props.currency.unit}
                                        value={props.price.price}
                                        onChange={(e) =>
                                            e
                                                ? props.setPrice((prev) => {
                                                      return { ...prev, price: e.target.value }
                                                  })
                                                : props.setPrice((prev) => {
                                                      return { ...prev, price: null }
                                                  })
                                        }
                                    />
                                    <div>
                                        <p>Select Currency</p>
                                        <SelectField
                                            search_by="-- Select currency --"
                                            searchable={false}
                                            options={props.currencyOptions}
                                            value={props.currency}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    props.setCurrency(e)
                                                    props.setPrice((prev) => {
                                                        return { ...prev, currency: e.value }
                                                    })
                                                } else {
                                                    props.setCurrency('')
                                                    props.setPrice((prev) => {
                                                        return { ...prev, currency: '' }
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <MoqInputField
                                    label1="MOQ1"
                                    label2="Discount"
                                    label3="Discounted price"
                                    disabled={false}
                                    required={true}
                                    type="number"
                                    value1={props.price.moq}
                                    value2={props.price.discount}
                                    onChange1={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, moq: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, moq: null }
                                              })
                                    }
                                    onChange2={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, discount: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, discount: null }
                                              })
                                    }
                                    inputUnit={true}
                                    unit1=""
                                    unit2="%"
                                    regularPrice={props.price.price}
                                    currencyUnit={props.currency.unit}
                                    discountedPrice={props.price.offer_price}
                                />
                                <MoqInputField
                                    label1="MOQ2"
                                    label2="Discount"
                                    label3="Discounted price"
                                    disabled={false}
                                    required={false}
                                    type="number"
                                    value1={props.price.moq2}
                                    value2={props.price.discount2}
                                    onChange1={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, moq2: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, moq2: null }
                                              })
                                    }
                                    onChange2={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, discount2: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, discount2: null }
                                              })
                                    }
                                    inputUnit={true}
                                    unit1=""
                                    unit2="%"
                                    regularPrice={props.price.price}
                                    currencyUnit={props.currency.unit}
                                    discountedPrice={props.price.offer_price2}
                                />

                                <MoqInputField
                                    label1="MOQ3"
                                    label2="Discount"
                                    label3="Discounted price"
                                    disabled={false}
                                    required={false}
                                    type="number"
                                    value1={props.price.moq3}
                                    value2={props.price.discount3}
                                    onChange1={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, moq3: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, moq3: null }
                                              })
                                    }
                                    onChange2={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, discount3: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, discount3: null }
                                              })
                                    }
                                    inputUnit={true}
                                    unit1=""
                                    unit2="%"
                                    regularPrice={props.price.price}
                                    currencyUnit={props.currency.unit}
                                    discountedPrice={props.price.offer_price3}
                                />
                            </div>
                        </div>
                        <div>
                            <p>Shipping and Delivery</p>
                            <div>
                                <div className={classes.pricingField}>
                                    <InputField
                                        label="Product Weight"
                                        disabled={false}
                                        required={false}
                                        type="text"
                                        value={props.packages.weight}
                                        onChange={(e) =>
                                            e
                                                ? props.setPackages((prev) => {
                                                      return { ...prev, weight: e.target.value }
                                                  })
                                                : props.setPackages((prev) => {
                                                      return { ...prev, weight: null }
                                                  })
                                        }
                                        inputUnit={true}
                                        unit={props.weightUnit.label}
                                    />
                                    <div>
                                        <p>Select Unit</p>
                                        <SelectField
                                            search_by="-- Select unit --"
                                            searchable={false}
                                            options={props.weightUnitOptions}
                                            value={props.weightUnit}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    props.setWeightUnit(e)
                                                    props.setPackages((prev) => {
                                                        return { ...prev, weight_unit: e.value }
                                                    })
                                                } else {
                                                    props.setWeightUnit('')
                                                    props.setPackages((prev) => {
                                                        return { ...prev, weight_unit: null }
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.pricingField}>
                                    <MoqInputField
                                        label1="Height"
                                        label2="Width"
                                        label3="Length"
                                        disabled={false}
                                        required={false}
                                        type="number"
                                        value1={props.packages.height}
                                        value2={props.packages.width}
                                        value3={props.packages.length}
                                        onChange1={(e) =>
                                            e
                                                ? props.setPackages((prev) => {
                                                      return { ...prev, height: e.target.value }
                                                  })
                                                : props.setPackages((prev) => {
                                                      return { ...prev, height: null }
                                                  })
                                        }
                                        onChange2={(e) =>
                                            e
                                                ? props.setPackages((prev) => {
                                                      return { ...prev, width: e.target.value }
                                                  })
                                                : props.setPackages((prev) => {
                                                      return { ...prev, width: null }
                                                  })
                                        }
                                        onChange3={(e) =>
                                            e
                                                ? props.setPackages((prev) => {
                                                      return { ...prev, length: e.target.value }
                                                  })
                                                : props.setPackages((prev) => {
                                                      return { ...prev, length: null }
                                                  })
                                        }
                                        inputUnit={true}
                                        unit1={props.measurementUnit.label}
                                        unit2={props.measurementUnit.label}
                                        unit3={props.measurementUnit.label}
                                    />
                                    <div>
                                        <p>Select Unit</p>
                                        <SelectField
                                            search_by="-- Select unit --"
                                            searchable={false}
                                            options={props.measurementUnitOptions}
                                            value={props.measurementUnit}
                                            selectOnChange={(e) => {
                                                if (e) {
                                                    props.setMeasurmentUnit(e)
                                                } else {
                                                    props.setMeasurmentUnit('')
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.uploadImage}>
                            <p>Upload Image</p>
                            {props.imageAlert && (
                                <div className={classes.imageAlert}>Image size should be less than 1mb!</div>
                            )}
                            <div>
                                <ImageUpload
                                    label="Display Image"
                                    handleClick={props.handleDisplayClick}
                                    image={props.displayImage}
                                    setImage={props.setDisplayImage}
                                    handleChange={props.handleDisplayChange}
                                    imgRef={props.displayImageRef}
                                    imageshow="display"
                                />

                                <div>
                                    <ImageUpload
                                        label="Image 2"
                                        handleClick={props.handleImg2Click}
                                        image={props.image2}
                                        setImage={props.setImage2}
                                        inputDisable={false}
                                        handleChange={props.handleImage2Change}
                                        imgRef={props.image2Ref}
                                    />
                                    <ImageUpload
                                        label="Image 3"
                                        handleClick={props.handleImg3Click}
                                        image={props.image3}
                                        setImage={props.setImage3}
                                        inputDisable={false}
                                        handleChange={props.handleImage3Change}
                                        imgRef={props.image3Ref}
                                    />
                                    <ImageUpload
                                        label="Image 4"
                                        handleClick={props.handleImg4Click}
                                        image={props.image4}
                                        setImage={props.setImage4}
                                        inputDisable={false}
                                        handleChange={props.handleImage4Change}
                                        imgRef={props.image4Ref}
                                    />
                                    <ImageUpload
                                        label="Image 5"
                                        handleClick={props.handleImg5Click}
                                        image={props.image5}
                                        setImage={props.setImage5}
                                        inputDisable={false}
                                        handleChange={props.handleImage5Change}
                                        imgRef={props.image5Ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SubmitButton alertSubmit={props.alertSubmit} buttonFor="2" type="submit" />
                    </div>
                </form>
            </div>
        </Body>
    )
}
