import React from 'react'
import { Body, ComingSoon } from '../Resource'

export default function TradeService() {
    return (
        <Body>
            <ComingSoon title="Trade Service" />
        </Body>
    )
}
