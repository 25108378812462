import React from 'react'
import classes from './MoqInputField.module.css'

export default function MoqInputField(props) {
    return (
        <div className={classes.wrapper}>
            <div>
                <label>{props.label1}</label>
                <div className={classes.inputCurrency}>
                    {props.unit1 && <div>{props.unit1}</div>}
                    <input
                        disabled={props.disabled}
                        required={props.required}
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value1 ? props.value1 : ''}
                        onChange={props.onChange1}
                    />
                </div>
            </div>
            <div>
                <label>{props.label2}</label>
                <div className={classes.inputCurrency}>
                    <div>{props.unit2}</div>
                    <input
                        disabled={props.disabled}
                        required={false}
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value2 ? props.value2 : ''}
                        onChange={props.onChange2}
                    />
                </div>
            </div>
            {props.unit3 ? (
                <div>
                    <label>{props.label3}</label>
                    <div className={classes.inputCurrency}>
                        <div>{props.unit3}</div>
                        <input
                            disabled={props.disabled}
                            required={props.required}
                            type={props.type}
                            placeholder={props.placeholder}
                            value={props.value3 ? props.value3 : ''}
                            onChange={props.onChange3}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <label>
                        {props.label3}:{' '}
                        <span className={classes.priceText}>
                            {props.currencyUnit}
                            {props.discountedPrice}
                        </span>
                    </label>
                </div>
            )}
        </div>
    )
}
