import React, { useState } from 'react'
import { InputField, SubmitButton } from '../../Resource'
import { SelectField } from '../../Resource/SelectField/SelectField'
import classes from './CompanyInformation.module.css'

export default function CompanyInformation(props) {
    const [updateSave, setUpdateSave] = useState(false)
    const [selectCategory, setSelectCategory] = useState('')

    return (
        <div className={classes.wrapper}>
            <div>
                <p>Update your Company Details</p>
                <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateCompanyInformation}
                        getPageData={props.getPageData}
                    />
                </div>
            </div>
            <div>
                <InputField
                    label="Company Name"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.pageName}
                    onChange={(e) => props.setPageName(e.target.value)}
                />
                <InputField
                    label="Country"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.country}
                    onChange={(e) => props.setCountry(e.target.value)}
                />
                <InputField
                    label="State"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.city}
                    onChange={(e) => props.setCity(e.target.value)}
                />
                <InputField
                    label="Address"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.address}
                    onChange={(e) => props.setAddress(e.target.value)}
                />

                <div className={classes.selectField}>
                    <label>Industries/Categories</label>
                    <div className={!updateSave ? classes.selectFieldDisabled : classes.selectFieldActive}>
                        {!updateSave ? (
                            props.category
                        ) : (
                            <SelectField
                                category={props.category}
                                search_by="-- Select Industry/Category --"
                                searchable={true}
                                clearable={true}
                                options={props.categoryOptions}
                                value={selectCategory}
                                selectOnChange={(e) => {
                                    if (e) {
                                        setSelectCategory(e)
                                        props.setCategory(e.value)
                                    } else {
                                        setSelectCategory('')
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
                {/* <InputField
                    label="Industries/Categories"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.category}
                    onChange={(e) => props.setCategory(e.target.value)}
                /> */}
                <InputField
                    label="Person Name"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.pocName}
                    onChange={(e) => props.setPocName(e.target.value)}
                />
                <InputField
                    label="Person Contact"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.pocPhone}
                    onChange={(e) => props.setPocPhone(e.target.value)}
                />
                <InputField
                    label="Designation"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="text"
                    value={props.designation}
                    onChange={(e) => props.setDesignation(e.target.value)}
                />
                <InputField
                    label="About Company"
                    disabled={!updateSave ? true : false}
                    required={false}
                    type="textArea"
                    value={props.about}
                    onChange={(e) => props.setAbout(e.target.value)}
                />
            </div>
            <div>
                <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateCompanyInformation}
                        getPageData={props.getPageData}
                    />
                </div>
            </div>
        </div>
    )
}
