export const countryListEmoji = [
    {
        name: 'Ascension Island',
        code: 'AC',
        emoji: '🇦🇨',
        label: '🇦🇨 Ascension Island',
        value: 'Ascension Island',
    },
    {
        name: 'Andorra',
        code: 'AD',
        emoji: '🇦🇩',
        label: '🇦🇩 Andorra',
        value: 'Andorra',
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        emoji: '🇦🇪',
        label: '🇦🇪 United Arab Emirates',
        value: 'United Arab Emirates',
    },
    {
        name: 'Afghanistan',
        code: 'AF',
        emoji: '🇦🇫',
        label: '🇦🇫 Afghanistan',
        value: 'Afghanistan',
    },
    {
        name: 'Antigua & Barbuda',
        code: 'AG',
        emoji: '🇦🇬',
        label: '🇦🇬 Antigua & Barbuda',
        value: 'Antigua & Barbuda',
    },
    {
        name: 'Anguilla',
        code: 'AI',
        emoji: '🇦🇮',
        label: '🇦🇮 Anguilla',
        value: 'Anguilla',
    },
    {
        name: 'Albania',
        code: 'AL',
        emoji: '🇦🇱',
        label: '🇦🇱 Albania',
        value: 'Albania',
    },
    {
        name: 'Armenia',
        code: 'AM',
        emoji: '🇦🇲',
        label: '🇦🇲 Armenia',
        value: 'Armenia',
    },
    {
        name: 'Angola',
        code: 'AO',
        emoji: '🇦🇴',
        label: '🇦🇴 Angola',
        value: 'Angola',
    },
    {
        name: 'Antarctica',
        code: 'AQ',
        emoji: '🇦🇶',
        label: '🇦🇶 Antarctica',
        value: 'Antarctica',
    },
    {
        name: 'Argentina',
        code: 'AR',
        emoji: '🇦🇷',
        label: '🇦🇷 Argentina',
        value: 'Argentina',
    },
    {
        name: 'American Samoa',
        code: 'AS',
        emoji: '🇦🇸',
        label: '🇦🇸 American Samoa',
        value: 'American Samoa',
    },
    {
        name: 'Austria',
        code: 'AT',
        emoji: '🇦🇹',
        label: '🇦🇹 Austria',
        value: 'Austria',
    },
    {
        name: 'Australia',
        code: 'AU',
        emoji: '🇦🇺',
        label: '🇦🇺 Australia',
        value: 'Australia',
    },
    {
        name: 'Aruba',
        code: 'AW',
        emoji: '🇦🇼',
        label: '🇦🇼 Aruba',
        value: 'Aruba',
    },
    {
        name: 'Åland Islands',
        code: 'AX',
        emoji: '🇦🇽',
        label: '🇦🇽 Åland Islands',
        value: 'Åland Islands',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        emoji: '🇦🇿',
        label: '🇦🇿 Azerbaijan',
        value: 'Azerbaijan',
    },
    {
        name: 'Bosnia & Herzegovina',
        code: 'BA',
        emoji: '🇧🇦',
        label: '🇧🇦 Bosnia & Herzegovina',
        value: 'Bosnia & Herzegovina',
    },
    {
        name: 'Barbados',
        code: 'BB',
        emoji: '🇧🇧',
        label: '🇧🇧 Barbados',
        value: 'Barbados',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        emoji: '🇧🇩',
        label: '🇧🇩 Bangladesh',
        value: 'Bangladesh',
    },
    {
        name: 'Belgium',
        code: 'BE',
        emoji: '🇧🇪',
        label: '🇧🇪 Belgium',
        value: 'Belgium',
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        emoji: '🇧🇫',
        label: '🇧🇫 Burkina Faso',
        value: 'Burkina Faso',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        emoji: '🇧🇬',
        label: '🇧🇬 Bulgaria',
        value: 'Bulgaria',
    },
    {
        name: 'Bahrain',
        code: 'BH',
        emoji: '🇧🇭',
        label: '🇧🇭 Bahrain',
        value: 'Bahrain',
    },
    {
        name: 'Burundi',
        code: 'BI',
        emoji: '🇧🇮',
        label: '🇧🇮 Burundi',
        value: 'Burundi',
    },
    {
        name: 'Benin',
        code: 'BJ',
        emoji: '🇧🇯',
        label: '🇧🇯 Benin',
        value: 'Benin',
    },
    {
        name: 'St. Barthélemy',
        code: 'BL',
        emoji: '🇧🇱',
        label: '🇧🇱 St. Barthélemy',
        value: 'St. Barthélemy',
    },
    {
        name: 'Bermuda',
        code: 'BM',
        emoji: '🇧🇲',
        label: '🇧🇲 Bermuda',
        value: 'Bermuda',
    },
    {
        name: 'Brunei',
        code: 'BN',
        emoji: '🇧🇳',
        label: '🇧🇳 Brunei',
        value: 'Brunei',
    },
    {
        name: 'Bolivia',
        code: 'BO',
        emoji: '🇧🇴',
        label: '🇧🇴 Bolivia',
        value: 'Bolivia',
    },
    {
        name: 'Caribbean Netherlands',
        code: 'BQ',
        emoji: '🇧🇶',
        label: '🇧🇶 Caribbean Netherlands',
        value: 'Caribbean Netherlands',
    },
    {
        name: 'Brazil',
        code: 'BR',
        emoji: '🇧🇷',
        label: '🇧🇷 Brazil',
        value: 'Brazil',
    },
    {
        name: 'Bahamas',
        code: 'BS',
        emoji: '🇧🇸',
        label: '🇧🇸 Bahamas',
        value: 'Bahamas',
    },
    {
        name: 'Bhutan',
        code: 'BT',
        emoji: '🇧🇹',
        label: '🇧🇹 Bhutan',
        value: 'Bhutan',
    },
    {
        name: 'Bouvet Island',
        code: 'BV',
        emoji: '🇧🇻',
        label: '🇧🇻 Bouvet Island',
        value: 'Bouvet Island',
    },
    {
        name: 'Botswana',
        code: 'BW',
        emoji: '🇧🇼',
        label: '🇧🇼 Botswana',
        value: 'Botswana',
    },
    {
        name: 'Belarus',
        code: 'BY',
        emoji: '🇧🇾',
        label: '🇧🇾 Belarus',
        value: 'Belarus',
    },
    {
        name: 'Belize',
        code: 'BZ',
        emoji: '🇧🇿',
        label: '🇧🇿 Belize',
        value: 'Belize',
    },
    {
        name: 'Canada',
        code: 'CA',
        emoji: '🇨🇦',
        label: '🇨🇦 Canada',
        value: 'Canada',
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        emoji: '🇨🇨',
        label: '🇨🇨 Cocos (Keeling) Islands',
        value: 'Cocos (Keeling) Islands',
    },
    {
        name: 'Congo - Kinshasa',
        code: 'CD',
        emoji: '🇨🇩',
        label: '🇨🇩 Congo - Kinshasa',
        value: 'Congo - Kinshasa',
    },
    {
        name: 'Central African Republic',
        code: 'CF',
        emoji: '🇨🇫',
        label: '🇨🇫 Central African Republic',
        value: 'Central African Republic',
    },
    {
        name: 'Congo - Brazzaville',
        code: 'CG',
        emoji: '🇨🇬',
        label: '🇨🇬 Congo - Brazzaville',
        value: 'Congo - Brazzaville',
    },
    {
        name: 'Switzerland',
        code: 'CH',
        emoji: '🇨🇭',
        label: '🇨🇭 Switzerland',
        value: 'Switzerland',
    },
    {
        name: 'Côte d’Ivoire',
        code: 'CI',
        emoji: '🇨🇮',
        label: '🇨🇮 Côte d’Ivoire',
        value: 'Côte d’Ivoire',
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        emoji: '🇨🇰',
        label: '🇨🇰 Cook Islands',
        value: 'Cook Islands',
    },
    {
        name: 'Chile',
        code: 'CL',
        emoji: '🇨🇱',
        label: '🇨🇱 Chile',
        value: 'Chile',
    },
    {
        name: 'Cameroon',
        code: 'CM',
        emoji: '🇨🇲',
        label: '🇨🇲 Cameroon',
        value: 'Cameroon',
    },
    {
        name: 'China',
        code: 'CN',
        emoji: '🇨🇳',
        label: '🇨🇳 China',
        value: 'China',
    },
    {
        name: 'Colombia',
        code: 'CO',
        emoji: '🇨🇴',
        label: '🇨🇴 Colombia',
        value: 'Colombia',
    },
    {
        name: 'Clipperton Island',
        code: 'CP',
        emoji: '🇨🇵',
        label: '🇨🇵 Clipperton Island',
        value: 'Clipperton Island',
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        emoji: '🇨🇷',
        label: '🇨🇷 Costa Rica',
        value: 'Costa Rica',
    },
    {
        name: 'Cuba',
        code: 'CU',
        emoji: '🇨🇺',
        label: '🇨🇺 Cuba',
        value: 'Cuba',
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        emoji: '🇨🇻',
        label: '🇨🇻 Cape Verde',
        value: 'Cape Verde',
    },
    {
        name: 'Curaçao',
        code: 'CW',
        emoji: '🇨🇼',
        label: '🇨🇼 Curaçao',
        value: 'Curaçao',
    },
    {
        name: 'Christmas Island',
        code: 'CX',
        emoji: '🇨🇽',
        label: '🇨🇽 Christmas Island',
        value: 'Christmas Island',
    },
    {
        name: 'Cyprus',
        code: 'CY',
        emoji: '🇨🇾',
        label: '🇨🇾 Cyprus',
        value: 'Cyprus',
    },
    {
        name: 'Czechia',
        code: 'CZ',
        emoji: '🇨🇿',
        label: '🇨🇿 Czechia',
        value: 'Czechia',
    },
    {
        name: 'Germany',
        code: 'DE',
        emoji: '🇩🇪',
        label: '🇩🇪 Germany',
        value: 'Germany',
    },
    {
        name: 'Diego Garcia',
        code: 'DG',
        emoji: '🇩🇬',
        label: '🇩🇬 Diego Garcia',
        value: 'Diego Garcia',
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        emoji: '🇩🇯',
        label: '🇩🇯 Djibouti',
        value: 'Djibouti',
    },
    {
        name: 'Denmark',
        code: 'DK',
        emoji: '🇩🇰',
        label: '🇩🇰 Denmark',
        value: 'Denmark',
    },
    {
        name: 'Dominica',
        code: 'DM',
        emoji: '🇩🇲',
        label: '🇩🇲 Dominica',
        value: 'Dominica',
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        emoji: '🇩🇴',
        label: '🇩🇴 Dominican Republic',
        value: 'Dominican Republic',
    },
    {
        name: 'Algeria',
        code: 'DZ',
        emoji: '🇩🇿',
        label: '🇩🇿 Algeria',
        value: 'Algeria',
    },
    {
        name: 'Ceuta & Melilla',
        code: 'EA',
        emoji: '🇪🇦',
        label: '🇪🇦 Ceuta & Melilla',
        value: 'Ceuta & Melilla',
    },
    {
        name: 'Ecuador',
        code: 'EC',
        emoji: '🇪🇨',
        label: '🇪🇨 Ecuador',
        value: 'Ecuador',
    },
    {
        name: 'Estonia',
        code: 'EE',
        emoji: '🇪🇪',
        label: '🇪🇪 Estonia',
        value: 'Estonia',
    },
    {
        name: 'Egypt',
        code: 'EG',
        emoji: '🇪🇬',
        label: '🇪🇬 Egypt',
        value: 'Egypt',
    },
    {
        name: 'Western Sahara',
        code: 'EH',
        emoji: '🇪🇭',
        label: '🇪🇭 Western Sahara',
        value: 'Western Sahara',
    },
    {
        name: 'Eritrea',
        code: 'ER',
        emoji: '🇪🇷',
        label: '🇪🇷 Eritrea',
        value: 'Eritrea',
    },
    {
        name: 'Spain',
        code: 'ES',
        emoji: '🇪🇸',
        label: '🇪🇸 Spain',
        value: 'Spain',
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        emoji: '🇪🇹',
        label: '🇪🇹 Ethiopia',
        value: 'Ethiopia',
    },
    {
        name: 'European Union',
        code: 'EU',
        emoji: '🇪🇺',
        label: '🇪🇺 European Union',
        value: 'European Union',
    },
    {
        name: 'Finland',
        code: 'FI',
        emoji: '🇫🇮',
        label: '🇫🇮 Finland',
        value: 'Finland',
    },
    {
        name: 'Fiji',
        code: 'FJ',
        emoji: '🇫🇯',
        label: '🇫🇯 Fiji',
        value: 'Fiji',
    },
    {
        name: 'Falkland Islands',
        code: 'FK',
        emoji: '🇫🇰',
        label: '🇫🇰 Falkland Islands',
        value: 'Falkland Islands',
    },
    {
        name: 'Micronesia',
        code: 'FM',
        emoji: '🇫🇲',
        label: '🇫🇲 Micronesia',
        value: 'Micronesia',
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        emoji: '🇫🇴',
        label: '🇫🇴 Faroe Islands',
        value: 'Faroe Islands',
    },
    {
        name: 'France',
        code: 'FR',
        emoji: '🇫🇷',
        label: '🇫🇷 France',
        value: 'France',
    },
    {
        name: 'Gabon',
        code: 'GA',
        emoji: '🇬🇦',
        label: '🇬🇦 Gabon',
        value: 'Gabon',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        emoji: '🇬🇧',
        label: '🇬🇧 United Kingdom',
        value: 'United Kingdom',
    },
    {
        name: 'Grenada',
        code: 'GD',
        emoji: '🇬🇩',
        label: '🇬🇩 Grenada',
        value: 'Grenada',
    },
    {
        name: 'Georgia',
        code: 'GE',
        emoji: '🇬🇪',
        label: '🇬🇪 Georgia',
        value: 'Georgia',
    },
    {
        name: 'French Guiana',
        code: 'GF',
        emoji: '🇬🇫',
        label: '🇬🇫 French Guiana',
        value: 'French Guiana',
    },
    {
        name: 'Guernsey',
        code: 'GG',
        emoji: '🇬🇬',
        label: '🇬🇬 Guernsey',
        value: 'Guernsey',
    },
    {
        name: 'Ghana',
        code: 'GH',
        emoji: '🇬🇭',
        label: '🇬🇭 Ghana',
        value: 'Ghana',
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        emoji: '🇬🇮',
        label: '🇬🇮 Gibraltar',
        value: 'Gibraltar',
    },
    {
        name: 'Greenland',
        code: 'GL',
        emoji: '🇬🇱',
        label: '🇬🇱 Greenland',
        value: 'Greenland',
    },
    {
        name: 'Gambia',
        code: 'GM',
        emoji: '🇬🇲',
        label: '🇬🇲 Gambia',
        value: 'Gambia',
    },
    {
        name: 'Guinea',
        code: 'GN',
        emoji: '🇬🇳',
        label: '🇬🇳 Guinea',
        value: 'Guinea',
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        emoji: '🇬🇵',
        label: '🇬🇵 Guadeloupe',
        value: 'Guadeloupe',
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        emoji: '🇬🇶',
        label: '🇬🇶 Equatorial Guinea',
        value: 'Equatorial Guinea',
    },
    {
        name: 'Greece',
        code: 'GR',
        emoji: '🇬🇷',
        label: '🇬🇷 Greece',
        value: 'Greece',
    },
    {
        name: 'South Georgia & South Sandwich Islands',
        code: 'GS',
        emoji: '🇬🇸',
        label: '🇬🇸 South Georgia & South Sandwich Islands',
        value: 'South Georgia & South Sandwich Islands',
    },
    {
        name: 'Guatemala',
        code: 'GT',
        emoji: '🇬🇹',
        label: '🇬🇹 Guatemala',
        value: 'Guatemala',
    },
    {
        name: 'Guam',
        code: 'GU',
        emoji: '🇬🇺',
        label: '🇬🇺 Guam',
        value: 'Guam',
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        emoji: '🇬🇼',
        label: '🇬🇼 Guinea-Bissau',
        value: 'Guinea-Bissau',
    },
    {
        name: 'Guyana',
        code: 'GY',
        emoji: '🇬🇾',
        label: '🇬🇾 Guyana',
        value: 'Guyana',
    },
    {
        name: 'Hong Kong SAR China',
        code: 'HK',
        emoji: '🇭🇰',
        label: '🇭🇰 Hong Kong SAR China',
        value: 'Hong Kong SAR China',
    },
    {
        name: 'Heard & McDonald Islands',
        code: 'HM',
        emoji: '🇭🇲',
        label: '🇭🇲 Heard & McDonald Islands',
        value: 'Heard & McDonald Islands',
    },
    {
        name: 'Honduras',
        code: 'HN',
        emoji: '🇭🇳',
        label: '🇭🇳 Honduras',
        value: 'Honduras',
    },
    {
        name: 'Croatia',
        code: 'HR',
        emoji: '🇭🇷',
        label: '🇭🇷 Croatia',
        value: 'Croatia',
    },
    {
        name: 'Haiti',
        code: 'HT',
        emoji: '🇭🇹',
        label: '🇭🇹 Haiti',
        value: 'Haiti',
    },
    {
        name: 'Hungary',
        code: 'HU',
        emoji: '🇭🇺',
        label: '🇭🇺 Hungary',
        value: 'Hungary',
    },
    {
        name: 'Canary Islands',
        code: 'IC',
        emoji: '🇮🇨',
        label: '🇮🇨 Canary Islands',
        value: 'Canary Islands',
    },
    {
        name: 'Indonesia',
        code: 'ID',
        emoji: '🇮🇩',
        label: '🇮🇩 Indonesia',
        value: 'Indonesia',
    },
    {
        name: 'Ireland',
        code: 'IE',
        emoji: '🇮🇪',
        label: '🇮🇪 Ireland',
        value: 'Ireland',
    },
    {
        name: 'Israel',
        code: 'IL',
        emoji: '🇮🇱',
        label: '🇮🇱 Israel',
        value: 'Israel',
    },
    {
        name: 'Isle of Man',
        code: 'IM',
        emoji: '🇮🇲',
        label: '🇮🇲 Isle of Man',
        value: 'Isle of Man',
    },
    {
        name: 'India',
        code: 'IN',
        emoji: '🇮🇳',
        label: '🇮🇳 India',
        value: 'India',
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        emoji: '🇮🇴',
        label: '🇮🇴 British Indian Ocean Territory',
        value: 'British Indian Ocean Territory',
    },
    {
        name: 'Iraq',
        code: 'IQ',
        emoji: '🇮🇶',
        label: '🇮🇶 Iraq',
        value: 'Iraq',
    },
    {
        name: 'Iran',
        code: 'IR',
        emoji: '🇮🇷',
        label: '🇮🇷 Iran',
        value: 'Iran',
    },
    {
        name: 'Iceland',
        code: 'IS',
        emoji: '🇮🇸',
        label: '🇮🇸 Iceland',
        value: 'Iceland',
    },
    {
        name: 'Italy',
        code: 'IT',
        emoji: '🇮🇹',
        label: '🇮🇹 Italy',
        value: 'Italy',
    },
    {
        name: 'Jersey',
        code: 'JE',
        emoji: '🇯🇪',
        label: '🇯🇪 Jersey',
        value: 'Jersey',
    },
    {
        name: 'Jamaica',
        code: 'JM',
        emoji: '🇯🇲',
        label: '🇯🇲 Jamaica',
        value: 'Jamaica',
    },
    {
        name: 'Jordan',
        code: 'JO',
        emoji: '🇯🇴',
        label: '🇯🇴 Jordan',
        value: 'Jordan',
    },
    {
        name: 'Japan',
        code: 'JP',
        emoji: '🇯🇵',
        label: '🇯🇵 Japan',
        value: 'Japan',
    },
    {
        name: 'Kenya',
        code: 'KE',
        emoji: '🇰🇪',
        label: '🇰🇪 Kenya',
        value: 'Kenya',
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        emoji: '🇰🇬',
        label: '🇰🇬 Kyrgyzstan',
        value: 'Kyrgyzstan',
    },
    {
        name: 'Cambodia',
        code: 'KH',
        emoji: '🇰🇭',
        label: '🇰🇭 Cambodia',
        value: 'Cambodia',
    },
    {
        name: 'Kiribati',
        code: 'KI',
        emoji: '🇰🇮',
        label: '🇰🇮 Kiribati',
        value: 'Kiribati',
    },
    {
        name: 'Comoros',
        code: 'KM',
        emoji: '🇰🇲',
        label: '🇰🇲 Comoros',
        value: 'Comoros',
    },
    {
        name: 'St. Kitts & Nevis',
        code: 'KN',
        emoji: '🇰🇳',
        label: '🇰🇳 St. Kitts & Nevis',
        value: 'St. Kitts & Nevis',
    },
    {
        name: 'North Korea',
        code: 'KP',
        emoji: '🇰🇵',
        label: '🇰🇵 North Korea',
        value: 'North Korea',
    },
    {
        name: 'South Korea',
        code: 'KR',
        emoji: '🇰🇷',
        label: '🇰🇷 South Korea',
        value: 'South Korea',
    },
    {
        name: 'Kuwait',
        code: 'KW',
        emoji: '🇰🇼',
        label: '🇰🇼 Kuwait',
        value: 'Kuwait',
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        emoji: '🇰🇾',
        label: '🇰🇾 Cayman Islands',
        value: 'Cayman Islands',
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        emoji: '🇰🇿',
        label: '🇰🇿 Kazakhstan',
        value: 'Kazakhstan',
    },
    {
        name: 'Laos',
        code: 'LA',
        emoji: '🇱🇦',
        label: '🇱🇦 Laos',
        value: 'Laos',
    },
    {
        name: 'Lebanon',
        code: 'LB',
        emoji: '🇱🇧',
        label: '🇱🇧 Lebanon',
        value: 'Lebanon',
    },
    {
        name: 'St. Lucia',
        code: 'LC',
        emoji: '🇱🇨',
        label: '🇱🇨 St. Lucia',
        value: 'St. Lucia',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
        emoji: '🇱🇮',
        label: '🇱🇮 Liechtenstein',
        value: 'Liechtenstein',
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        emoji: '🇱🇰',
        label: '🇱🇰 Sri Lanka',
        value: 'Sri Lanka',
    },
    {
        name: 'Liberia',
        code: 'LR',
        emoji: '🇱🇷',
        label: '🇱🇷 Liberia',
        value: 'Liberia',
    },
    {
        name: 'Lesotho',
        code: 'LS',
        emoji: '🇱🇸',
        label: '🇱🇸 Lesotho',
        value: 'Lesotho',
    },
    {
        name: 'Lithuania',
        code: 'LT',
        emoji: '🇱🇹',
        label: '🇱🇹 Lithuania',
        value: 'Lithuania',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        emoji: '🇱🇺',
        label: '🇱🇺 Luxembourg',
        value: 'Luxembourg',
    },
    {
        name: 'Latvia',
        code: 'LV',
        emoji: '🇱🇻',
        label: '🇱🇻 Latvia',
        value: 'Latvia',
    },
    {
        name: 'Libya',
        code: 'LY',
        emoji: '🇱🇾',
        label: '🇱🇾 Libya',
        value: 'Libya',
    },
    {
        name: 'Morocco',
        code: 'MA',
        emoji: '🇲🇦',
        label: '🇲🇦 Morocco',
        value: 'Morocco',
    },
    {
        name: 'Monaco',
        code: 'MC',
        emoji: '🇲🇨',
        label: '🇲🇨 Monaco',
        value: 'Monaco',
    },
    {
        name: 'Moldova',
        code: 'MD',
        emoji: '🇲🇩',
        label: '🇲🇩 Moldova',
        value: 'Moldova',
    },
    {
        name: 'Montenegro',
        code: 'ME',
        emoji: '🇲🇪',
        label: '🇲🇪 Montenegro',
        value: 'Montenegro',
    },
    {
        name: 'St. Martin',
        code: 'MF',
        emoji: '🇲🇫',
        label: '🇲🇫 St. Martin',
        value: 'St. Martin',
    },
    {
        name: 'Madagascar',
        code: 'MG',
        emoji: '🇲🇬',
        label: '🇲🇬 Madagascar',
        value: 'Madagascar',
    },
    {
        name: 'Marshall Islands',
        code: 'MH',
        emoji: '🇲🇭',
        label: '🇲🇭 Marshall Islands',
        value: 'Marshall Islands',
    },
    {
        name: 'North Macedonia',
        code: 'MK',
        emoji: '🇲🇰',
        label: '🇲🇰 North Macedonia',
        value: 'North Macedonia',
    },
    {
        name: 'Mali',
        code: 'ML',
        emoji: '🇲🇱',
        label: '🇲🇱 Mali',
        value: 'Mali',
    },
    {
        name: 'Myanmar (Burma)',
        code: 'MM',
        emoji: '🇲🇲',
        label: '🇲🇲 Myanmar (Burma)',
        value: 'Myanmar (Burma)',
    },
    {
        name: 'Mongolia',
        code: 'MN',
        emoji: '🇲🇳',
        label: '🇲🇳 Mongolia',
        value: 'Mongolia',
    },
    {
        name: 'Macao SAR China',
        code: 'MO',
        emoji: '🇲🇴',
        label: '🇲🇴 Macao SAR China',
        value: 'Macao SAR China',
    },
    {
        name: 'Northern Mariana Islands',
        code: 'MP',
        emoji: '🇲🇵',
        label: '🇲🇵 Northern Mariana Islands',
        value: 'Northern Mariana Islands',
    },
    {
        name: 'Martinique',
        code: 'MQ',
        emoji: '🇲🇶',
        label: '🇲🇶 Martinique',
        value: 'Martinique',
    },
    {
        name: 'Mauritania',
        code: 'MR',
        emoji: '🇲🇷',
        label: '🇲🇷 Mauritania',
        value: 'Mauritania',
    },
    {
        name: 'Montserrat',
        code: 'MS',
        emoji: '🇲🇸',
        label: '🇲🇸 Montserrat',
        value: 'Montserrat',
    },
    {
        name: 'Malta',
        code: 'MT',
        emoji: '🇲🇹',
        label: '🇲🇹 Malta',
        value: 'Malta',
    },
    {
        name: 'Mauritius',
        code: 'MU',
        emoji: '🇲🇺',
        label: '🇲🇺 Mauritius',
        value: 'Mauritius',
    },
    {
        name: 'Maldives',
        code: 'MV',
        emoji: '🇲🇻',
        label: '🇲🇻 Maldives',
        value: 'Maldives',
    },
    {
        name: 'Malawi',
        code: 'MW',
        emoji: '🇲🇼',
        label: '🇲🇼 Malawi',
        value: 'Malawi',
    },
    {
        name: 'Mexico',
        code: 'MX',
        emoji: '🇲🇽',
        label: '🇲🇽 Mexico',
        value: 'Mexico',
    },
    {
        name: 'Malaysia',
        code: 'MY',
        emoji: '🇲🇾',
        label: '🇲🇾 Malaysia',
        value: 'Malaysia',
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        emoji: '🇲🇿',
        label: '🇲🇿 Mozambique',
        value: 'Mozambique',
    },
    {
        name: 'Namibia',
        code: 'NA',
        emoji: '🇳🇦',
        label: '🇳🇦 Namibia',
        value: 'Namibia',
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        emoji: '🇳🇨',
        label: '🇳🇨 New Caledonia',
        value: 'New Caledonia',
    },
    {
        name: 'Niger',
        code: 'NE',
        emoji: '🇳🇪',
        label: '🇳🇪 Niger',
        value: 'Niger',
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
        emoji: '🇳🇫',
        label: '🇳🇫 Norfolk Island',
        value: 'Norfolk Island',
    },
    {
        name: 'Nigeria',
        code: 'NG',
        emoji: '🇳🇬',
        label: '🇳🇬 Nigeria',
        value: 'Nigeria',
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        emoji: '🇳🇮',
        label: '🇳🇮 Nicaragua',
        value: 'Nicaragua',
    },
    {
        name: 'Netherlands',
        code: 'NL',
        emoji: '🇳🇱',
        label: '🇳🇱 Netherlands',
        value: 'Netherlands',
    },
    {
        name: 'Norway',
        code: 'NO',
        emoji: '🇳🇴',
        label: '🇳🇴 Norway',
        value: 'Norway',
    },
    {
        name: 'Nepal',
        code: 'NP',
        emoji: '🇳🇵',
        label: '🇳🇵 Nepal',
        value: 'Nepal',
    },
    {
        name: 'Nauru',
        code: 'NR',
        emoji: '🇳🇷',
        label: '🇳🇷 Nauru',
        value: 'Nauru',
    },
    {
        name: 'Niue',
        code: 'NU',
        emoji: '🇳🇺',
        label: '🇳🇺 Niue',
        value: 'Niue',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        emoji: '🇳🇿',
        label: '🇳🇿 New Zealand',
        value: 'New Zealand',
    },
    {
        name: 'Oman',
        code: 'OM',
        emoji: '🇴🇲',
        label: '🇴🇲 Oman',
        value: 'Oman',
    },
    {
        name: 'Panama',
        code: 'PA',
        emoji: '🇵🇦',
        label: '🇵🇦 Panama',
        value: 'Panama',
    },
    {
        name: 'Peru',
        code: 'PE',
        emoji: '🇵🇪',
        label: '🇵🇪 Peru',
        value: 'Peru',
    },
    {
        name: 'French Polynesia',
        code: 'PF',
        emoji: '🇵🇫',
        label: '🇵🇫 French Polynesia',
        value: 'French Polynesia',
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        emoji: '🇵🇬',
        label: '🇵🇬 Papua New Guinea',
        value: 'Papua New Guinea',
    },
    {
        name: 'Philippines',
        code: 'PH',
        emoji: '🇵🇭',
        label: '🇵🇭 Philippines',
        value: 'Philippines',
    },
    {
        name: 'Pakistan',
        code: 'PK',
        emoji: '🇵🇰',
        label: '🇵🇰 Pakistan',
        value: 'Pakistan',
    },
    {
        name: 'Poland',
        code: 'PL',
        emoji: '🇵🇱',
        label: '🇵🇱 Poland',
        value: 'Poland',
    },
    {
        name: 'St. Pierre & Miquelon',
        code: 'PM',
        emoji: '🇵🇲',
        label: '🇵🇲 St. Pierre & Miquelon',
        value: 'St. Pierre & Miquelon',
    },
    {
        name: 'Pitcairn Islands',
        code: 'PN',
        emoji: '🇵🇳',
        label: '🇵🇳 Pitcairn Islands',
        value: 'Pitcairn Islands',
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
        emoji: '🇵🇷',
        label: '🇵🇷 Puerto Rico',
        value: 'Puerto Rico',
    },
    {
        name: 'Palestinian Territories',
        code: 'PS',
        emoji: '🇵🇸',
        label: '🇵🇸 Palestinian Territories',
        value: 'Palestinian Territories',
    },
    {
        name: 'Portugal',
        code: 'PT',
        emoji: '🇵🇹',
        label: '🇵🇹 Portugal',
        value: 'Portugal',
    },
    {
        name: 'Palau',
        code: 'PW',
        emoji: '🇵🇼',
        label: '🇵🇼 Palau',
        value: 'Palau',
    },
    {
        name: 'Paraguay',
        code: 'PY',
        emoji: '🇵🇾',
        label: '🇵🇾 Paraguay',
        value: 'Paraguay',
    },
    {
        name: 'Qatar',
        code: 'QA',
        emoji: '🇶🇦',
        label: '🇶🇦 Qatar',
        value: 'Qatar',
    },
    {
        name: 'Réunion',
        code: 'RE',
        emoji: '🇷🇪',
        label: '🇷🇪 Réunion',
        value: 'Réunion',
    },
    {
        name: 'Romania',
        code: 'RO',
        emoji: '🇷🇴',
        label: '🇷🇴 Romania',
        value: 'Romania',
    },
    {
        name: 'Serbia',
        code: 'RS',
        emoji: '🇷🇸',
        label: '🇷🇸 Serbia',
        value: 'Serbia',
    },
    {
        name: 'Russia',
        code: 'RU',
        emoji: '🇷🇺',
        label: '🇷🇺 Russia',
        value: 'Russia',
    },
    {
        name: 'Rwanda',
        code: 'RW',
        emoji: '🇷🇼',
        label: '🇷🇼 Rwanda',
        value: 'Rwanda',
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        emoji: '🇸🇦',
        label: '🇸🇦 Saudi Arabia',
        value: 'Saudi Arabia',
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        emoji: '🇸🇧',
        label: '🇸🇧 Solomon Islands',
        value: 'Solomon Islands',
    },
    {
        name: 'Seychelles',
        code: 'SC',
        emoji: '🇸🇨',
        label: '🇸🇨 Seychelles',
        value: 'Seychelles',
    },
    {
        name: 'Sudan',
        code: 'SD',
        emoji: '🇸🇩',
        label: '🇸🇩 Sudan',
        value: 'Sudan',
    },
    {
        name: 'Sweden',
        code: 'SE',
        emoji: '🇸🇪',
        label: '🇸🇪 Sweden',
        value: 'Sweden',
    },
    {
        name: 'Singapore',
        code: 'SG',
        emoji: '🇸🇬',
        label: '🇸🇬 Singapore',
        value: 'Singapore',
    },
    {
        name: 'St. Helena',
        code: 'SH',
        emoji: '🇸🇭',
        label: '🇸🇭 St. Helena',
        value: 'St. Helena',
    },
    {
        name: 'Slovenia',
        code: 'SI',
        emoji: '🇸🇮',
        label: '🇸🇮 Slovenia',
        value: 'Slovenia',
    },
    {
        name: 'Svalbard & Jan Mayen',
        code: 'SJ',
        emoji: '🇸🇯',
        label: '🇸🇯 Svalbard & Jan Mayen',
        value: 'Svalbard & Jan Mayen',
    },
    {
        name: 'Slovakia',
        code: 'SK',
        emoji: '🇸🇰',
        label: '🇸🇰 Slovakia',
        value: 'Slovakia',
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        emoji: '🇸🇱',
        label: '🇸🇱 Sierra Leone',
        value: 'Sierra Leone',
    },
    {
        name: 'San Marino',
        code: 'SM',
        emoji: '🇸🇲',
        label: '🇸🇲 San Marino',
        value: 'San Marino',
    },
    {
        name: 'Senegal',
        code: 'SN',
        emoji: '🇸🇳',
        label: '🇸🇳 Senegal',
        value: 'Senegal',
    },
    {
        name: 'Somalia',
        code: 'SO',
        emoji: '🇸🇴',
        label: '🇸🇴 Somalia',
        value: 'Somalia',
    },
    {
        name: 'Suriname',
        code: 'SR',
        emoji: '🇸🇷',
        label: '🇸🇷 Suriname',
        value: 'Suriname',
    },
    {
        name: 'South Sudan',
        code: 'SS',
        emoji: '🇸🇸',
        label: '🇸🇸 South Sudan',
        value: 'South Sudan',
    },
    {
        name: 'São Tomé & Príncipe',
        code: 'ST',
        emoji: '🇸🇹',
        label: '🇸🇹 São Tomé & Príncipe',
        value: 'São Tomé & Príncipe',
    },
    {
        name: 'El Salvador',
        code: 'SV',
        emoji: '🇸🇻',
        label: '🇸🇻 El Salvador',
        value: 'El Salvador',
    },
    {
        name: 'Sint Maarten',
        code: 'SX',
        emoji: '🇸🇽',
        label: '🇸🇽 Sint Maarten',
        value: 'Sint Maarten',
    },
    {
        name: 'Syria',
        code: 'SY',
        emoji: '🇸🇾',
        label: '🇸🇾 Syria',
        value: 'Syria',
    },
    {
        name: 'Eswatini',
        code: 'SZ',
        emoji: '🇸🇿',
        label: '🇸🇿 Eswatini',
        value: 'Eswatini',
    },
    {
        name: 'Tristan da Cunha',
        code: 'TA',
        emoji: '🇹🇦',
        label: '🇹🇦 Tristan da Cunha',
        value: 'Tristan da Cunha',
    },
    {
        name: 'Turks & Caicos Islands',
        code: 'TC',
        emoji: '🇹🇨',
        label: '🇹🇨 Turks & Caicos Islands',
        value: 'Turks & Caicos Islands',
    },
    {
        name: 'Chad',
        code: 'TD',
        emoji: '🇹🇩',
        label: '🇹🇩 Chad',
        value: 'Chad',
    },
    {
        name: 'French Southern Territories',
        code: 'TF',
        emoji: '🇹🇫',
        label: '🇹🇫 French Southern Territories',
        value: 'French Southern Territories',
    },
    {
        name: 'Togo',
        code: 'TG',
        emoji: '🇹🇬',
        label: '🇹🇬 Togo',
        value: 'Togo',
    },
    {
        name: 'Thailand',
        code: 'TH',
        emoji: '🇹🇭',
        label: '🇹🇭 Thailand',
        value: 'Thailand',
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        emoji: '🇹🇯',
        label: '🇹🇯 Tajikistan',
        value: 'Tajikistan',
    },
    {
        name: 'Tokelau',
        code: 'TK',
        emoji: '🇹🇰',
        label: '🇹🇰 Tokelau',
        value: 'Tokelau',
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        emoji: '🇹🇱',
        label: '🇹🇱 Timor-Leste',
        value: 'Timor-Leste',
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
        emoji: '🇹🇲',
        label: '🇹🇲 Turkmenistan',
        value: 'Turkmenistan',
    },
    {
        name: 'Tunisia',
        code: 'TN',
        emoji: '🇹🇳',
        label: '🇹🇳 Tunisia',
        value: 'Tunisia',
    },
    {
        name: 'Tonga',
        code: 'TO',
        emoji: '🇹🇴',
        label: '🇹🇴 Tonga',
        value: 'Tonga',
    },
    {
        name: 'Turkey',
        code: 'TR',
        emoji: '🇹🇷',
        label: '🇹🇷 Turkey',
        value: 'Turkey',
    },
    {
        name: 'Trinidad & Tobago',
        code: 'TT',
        emoji: '🇹🇹',
        label: '🇹🇹 Trinidad & Tobago',
        value: 'Trinidad & Tobago',
    },
    {
        name: 'Tuvalu',
        code: 'TV',
        emoji: '🇹🇻',
        label: '🇹🇻 Tuvalu',
        value: 'Tuvalu',
    },
    {
        name: 'Taiwan',
        code: 'TW',
        emoji: '🇹🇼',
        label: '🇹🇼 Taiwan',
        value: 'Taiwan',
    },
    {
        name: 'Tanzania',
        code: 'TZ',
        emoji: '🇹🇿',
        label: '🇹🇿 Tanzania',
        value: 'Tanzania',
    },
    {
        name: 'Ukraine',
        code: 'UA',
        emoji: '🇺🇦',
        label: '🇺🇦 Ukraine',
        value: 'Ukraine',
    },
    {
        name: 'Uganda',
        code: 'UG',
        emoji: '🇺🇬',
        label: '🇺🇬 Uganda',
        value: 'Uganda',
    },
    {
        name: 'U.S. Outlying Islands',
        code: 'UM',
        emoji: '🇺🇲',
        label: '🇺🇲 U.S. Outlying Islands',
        value: 'U.S. Outlying Islands',
    },
    {
        name: 'United Nations',
        code: 'UN',
        emoji: '🇺🇳',
        label: '🇺🇳 United Nations',
        value: 'United Nations',
    },
    {
        name: 'United States',
        code: 'US',
        emoji: '🇺🇸',
        label: '🇺🇸 United States',
        value: 'United States',
    },
    {
        name: 'Uruguay',
        code: 'UY',
        emoji: '🇺🇾',
        label: '🇺🇾 Uruguay',
        value: 'Uruguay',
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        emoji: '🇺🇿',
        label: '🇺🇿 Uzbekistan',
        value: 'Uzbekistan',
    },
    {
        name: 'Vatican City',
        code: 'VA',
        emoji: '🇻🇦',
        label: '🇻🇦 Vatican City',
        value: 'Vatican City',
    },
    {
        name: 'St. Vincent & Grenadines',
        code: 'VC',
        emoji: '🇻🇨',
        label: '🇻🇨 St. Vincent & Grenadines',
        value: 'St. Vincent & Grenadines',
    },
    {
        name: 'Venezuela',
        code: 'VE',
        emoji: '🇻🇪',
        label: '🇻🇪 Venezuela',
        value: 'Venezuela',
    },
    {
        name: 'British Virgin Islands',
        code: 'VG',
        emoji: '🇻🇬',
        label: '🇻🇬 British Virgin Islands',
        value: 'British Virgin Islands',
    },
    {
        name: 'U.S. Virgin Islands',
        code: 'VI',
        emoji: '🇻🇮',
        label: '🇻🇮 U.S. Virgin Islands',
        value: 'U.S. Virgin Islands',
    },
    {
        name: 'Vietnam',
        code: 'VN',
        emoji: '🇻🇳',
        label: '🇻🇳 Vietnam',
        value: 'Vietnam',
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        emoji: '🇻🇺',
        label: '🇻🇺 Vanuatu',
        value: 'Vanuatu',
    },
    {
        name: 'Wallis & Futuna',
        code: 'WF',
        emoji: '🇼🇫',
        label: '🇼🇫 Wallis & Futuna',
        value: 'Wallis & Futuna',
    },
    {
        name: 'Samoa',
        code: 'WS',
        emoji: '🇼🇸',
        label: '🇼🇸 Samoa',
        value: 'Samoa',
    },
    {
        name: 'Kosovo',
        code: 'XK',
        emoji: '🇽🇰',
        label: '🇽🇰 Kosovo',
        value: 'Kosovo',
    },
    {
        name: 'Yemen',
        code: 'YE',
        emoji: '🇾🇪',
        label: '🇾🇪 Yemen',
        value: 'Yemen',
    },
    {
        name: 'Mayotte',
        code: 'YT',
        emoji: '🇾🇹',
        label: '🇾🇹 Mayotte',
        value: 'Mayotte',
    },
    {
        name: 'South Africa',
        code: 'ZA',
        emoji: '🇿🇦',
        label: '🇿🇦 South Africa',
        value: 'South Africa',
    },
    {
        name: 'Zambia',
        code: 'ZM',
        emoji: '🇿🇲',
        label: '🇿🇲 Zambia',
        value: 'Zambia',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        emoji: '🇿🇼',
        label: '🇿🇼 Zimbabwe',
        value: 'Zimbabwe',
    },
    {
        name: 'England',
        code: 'ENGLAND',
        emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
        label: '🏴󠁧󠁢󠁥󠁮󠁧󠁿 England',
        value: 'England',
    },
    {
        name: 'Scotland',
        code: 'SCOTLAND',
        emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
        label: '🏴󠁧󠁢󠁳󠁣󠁴󠁿 Scotland',
        value: 'Scotland',
    },
    {
        name: 'Wales',
        code: 'WALES',
        emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
        label: '🏴󠁧󠁢󠁷󠁬󠁳󠁿 Wales',
        value: 'Wales',
    },
]
