import React, { useState } from 'react'
import { AiFillCloseSquare } from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import { ImageUpload, InputField, MoqInputField, SubmitButton } from '../..'
import DoubleInputField from '../../DoubleInputField/DoubleInputField'
import { SelectField } from '../../SelectField/SelectField'
import classes from './ProductEdit.module.css'

export default function ProductEdit(props) {
    const [updateSave, setUpdateSave] = useState(false)
    if (props.activePopUp) {
        document.body.classList.add(classes.activeModel)
    } else {
        document.body.classList.remove(classes.activeModel)
    }
    const getPageData = () => {}
    const [click, setClick] = useState(0)

    return (
        <div className={classes.wrapper}>
            {/* <PopUp
                btnClickPopUp={props.btnClickPopUp}
                activePopUp={props.activePopUp}
                setActivePopUp={props.setActivePopUp}
            /> */}
            <div>
                <p>Product Edit/Update</p>
                <div
                    className={classes.closeBtn}
                    onClick={() => {
                        props.setActivePopUp(false)
                        props.setCategory('')
                        props.setSubCategory('')
                        props.setSubSubCategory('')
                    }}>
                    <AiFillCloseSquare className={classes.iconClose} />
                </div>
            </div>
            <div className={classes.container}>
                <div className={click === 1 ? `${classes.open} ${classes.category}` : classes.close}>
                    <div>
                        <p
                            onClick={() => {
                                if (click !== 1) {
                                    setClick(1)
                                    updateSave && setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Category <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div className={classes.currentCategory}>
                            <div>Category: {props.currentCategory}</div>
                            {'|'}
                            <div>Sub Category: {props.currentSubCategory}</div>
                            {'|'}
                            <div> Sub Sub Category: {props.currentSubSubCategory}</div>
                        </div>
                        <div className={classes.newCategory}>
                            <div className={classes.inputMessage}>
                                <SelectField
                                    search_by="-- Select category --"
                                    searchable={true}
                                    clearable={true}
                                    options={props.categoryOption}
                                    value={props.category}
                                    selectOnChange={(e) => {
                                        if (e) {
                                            props.setCategory(e)
                                            props.setSubCategory('')
                                            props.setSubSubCategory('')
                                        } else {
                                            props.setCategory('')
                                            props.setSubCategory('')
                                            props.setSubSubCategory('')
                                        }
                                    }}
                                />
                            </div>

                            {props.category && (
                                <div className={classes.inputMessage}>
                                    <SelectField
                                        search_by="-- Select sub category --"
                                        searchable={true}
                                        clearable={true}
                                        options={props.subCategoryOption}
                                        value={props.subCategory}
                                        selectOnChange={(e) => {
                                            if (e) {
                                                props.setSubCategory(e)
                                                props.setSubSubCategory('')
                                            } else {
                                                props.setSubCategory('')
                                                props.setSubSubCategory('')
                                            }
                                        }}
                                    />
                                </div>
                            )}

                            {props.category && props.subCategory && (
                                <div className={classes.inputMessage}>
                                    <SelectField
                                        search_by="-- Select sub sub category --"
                                        searchable={true}
                                        clearable={true}
                                        options={props.subSubCategoryOption}
                                        value={props.subSubCategory}
                                        selectOnChange={(e) => {
                                            if (e) {
                                                props.setSubSubCategory(e)
                                            } else {
                                                props.setSubSubCategory('')
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            <div className={classes.submitButtonDiv}>
                                {props.updated ? (
                                    <div className={classes.successMsg}>Updated successfully!</div>
                                ) : (
                                    props.category &&
                                    props.subCategory &&
                                    props.subSubCategory && (
                                        <SubmitButton
                                            updateSave={updateSave}
                                            setUpdateSave={setUpdateSave}
                                            updateInformation={props.updateCategory}
                                            getPageData={getPageData}
                                            type="submit"
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={click === 2 ? `${classes.open} ${classes.product}` : classes.close}>
                    <div>
                        <p
                            onClick={() => {
                                if (click !== 2) {
                                    setClick(2)
                                    updateSave && setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Description <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div>
                            <InputField
                                label="Product Name"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.product.title}
                                onChange={(e) =>
                                    e
                                        ? props.setProduct((prev) => {
                                              return { ...prev, title: e.target.value }
                                          })
                                        : props.setProduct((prev) => {
                                              return { ...prev, title: null }
                                          })
                                }
                            />
                            <InputField
                                label="Product Short Description"
                                disabled={false}
                                required={false}
                                type="textArea"
                                value={props.product.description}
                                onChange={(e) =>
                                    e
                                        ? props.setProduct((prev) => {
                                              return { ...prev, description: e.target.value }
                                          })
                                        : props.setProduct((prev) => {
                                              return { ...prev, description: null }
                                          })
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <p>Inventory</p>
                        <div>
                            <InputField
                                label="Quantity"
                                disabled={false}
                                required={true}
                                type="text"
                                value={props.product.quantity}
                                onChange={(e) =>
                                    e
                                        ? props.setProduct((prev) => {
                                              return { ...prev, quantity: e.target.value }
                                          })
                                        : props.setProduct((prev) => {
                                              return { ...prev, quantity: null }
                                          })
                                }
                            />
                            <InputField
                                label="SKU"
                                disabled={false}
                                required={true}
                                type="text"
                                value={props.product.sku}
                                onChange={(e) =>
                                    e
                                        ? props.setProduct((prev) => {
                                              return { ...prev, sku: e.target.value }
                                          })
                                        : props.setProduct((prev) => {
                                              return { ...prev, sku: null }
                                          })
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.submitButtonDiv}>
                        {props.updated ? (
                            <div className={classes.successMsg}>Updated successfully!</div>
                        ) : (
                            <SubmitButton
                                updateSave={updateSave}
                                setUpdateSave={setUpdateSave}
                                updateInformation={props.updateProduct}
                                getPageData={getPageData}
                                type="submit"
                            />
                        )}
                    </div>
                </div>
                <div className={click === 3 ? `${classes.open} ${classes.price}` : classes.close}>
                    <div>
                        <p
                            onClick={() => {
                                if (click !== 3) {
                                    setClick(3)
                                    updateSave && setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Pricing <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div>
                            <div className={classes.pricingField}>
                                <InputField
                                    label="Regular Price"
                                    disabled={false}
                                    required={true}
                                    type="text"
                                    inputUnit={true}
                                    unit={props.currency.unit}
                                    value={props.price.price}
                                    onChange={(e) =>
                                        e
                                            ? props.setPrice((prev) => {
                                                  return { ...prev, price: e.target.value }
                                              })
                                            : props.setPrice((prev) => {
                                                  return { ...prev, price: null }
                                              })
                                    }
                                />
                                <div>
                                    <p>Select Currency</p>
                                    <SelectField
                                        search_by="-- Select currency --"
                                        searchable={false}
                                        options={props.currencyOptions}
                                        value={props.currency}
                                        selectOnChange={(e) => {
                                            if (e) {
                                                props.setCurrency(e)
                                                props.setPrice((prev) => {
                                                    return { ...prev, currency: e.value }
                                                })
                                            } else {
                                                props.setCurrency('')
                                                props.setPrice((prev) => {
                                                    return { ...prev, currency: '' }
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <MoqInputField
                                label1="MOQ1"
                                label2="Discount"
                                label3="Discounted price"
                                disabled={false}
                                required={true}
                                type="number"
                                value1={props.price.moq}
                                value2={props.price.discount}
                                onChange1={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, moq: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, moq: null }
                                          })
                                }
                                onChange2={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, discount: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, discount: null }
                                          })
                                }
                                inputUnit={true}
                                unit1=""
                                unit2="%"
                                regularPrice={props.price.price}
                                currencyUnit={props.currency.unit}
                                discountedPrice={props.price.offer_price}
                            />
                            <MoqInputField
                                label1="MOQ2"
                                label2="Discount"
                                label3="Discounted price"
                                disabled={false}
                                required={false}
                                type="number"
                                value1={props.price.moq2}
                                value2={props.price.discount2}
                                onChange1={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, moq2: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, moq2: null }
                                          })
                                }
                                onChange2={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, discount2: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, discount2: null }
                                          })
                                }
                                inputUnit={true}
                                unit1=""
                                unit2="%"
                                regularPrice={props.price.price}
                                currencyUnit={props.currency.unit}
                                discountedPrice={props.price.offer_price2}
                            />

                            <MoqInputField
                                label1="MOQ3"
                                label2="Discount"
                                label3="Discounted price"
                                disabled={false}
                                required={false}
                                type="number"
                                value1={props.price.moq3}
                                value2={props.price.discount3}
                                onChange1={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, moq3: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, moq3: null }
                                          })
                                }
                                onChange2={(e) =>
                                    e
                                        ? props.setPrice((prev) => {
                                              return { ...prev, discount3: e.target.value }
                                          })
                                        : props.setPrice((prev) => {
                                              return { ...prev, discount3: null }
                                          })
                                }
                                inputUnit={true}
                                unit1=""
                                unit2="%"
                                regularPrice={props.price.price}
                                currencyUnit={props.currency.unit}
                                discountedPrice={props.price.offer_price3}
                            />
                        </div>
                    </div>
                    <div className={classes.submitButtonDiv}>
                        <SubmitButton
                            updateSave={updateSave}
                            setUpdateSave={setUpdateSave}
                            updateInformation={props.updatePrice}
                            getPageData={getPageData}
                            type="submit"
                        />
                    </div>
                </div>
                <div className={click === 4 ? `${classes.open} ${classes.packages}` : classes.close}>
                    {/* <form onSubmit={(e) => props.handlePrice}> */}

                    <div>
                        <p
                            onClick={() => {
                                if (click !== 4) {
                                    setClick(4)
                                    props.updateSave && props.setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Shipping and Delivery <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div>
                            <div className={classes.pricingField}>
                                <InputField
                                    label="Product Weight"
                                    disabled={false}
                                    required={false}
                                    type="text"
                                    value={props.packages.weight}
                                    onChange={(e) =>
                                        e
                                            ? props.setPackages((prev) => {
                                                  return { ...prev, weight: e.target.value }
                                              })
                                            : props.setPackages((prev) => {
                                                  return { ...prev, weight: null }
                                              })
                                    }
                                    inputUnit={true}
                                    unit={props.weightUnit.label}
                                />
                                <div>
                                    <p>Select Unit</p>
                                    <SelectField
                                        search_by="-- Select unit --"
                                        searchable={false}
                                        options={props.weightUnitOptions}
                                        value={props.weightUnit}
                                        selectOnChange={(e) => {
                                            if (e) {
                                                props.setWeightUnit(e)
                                                props.setPackages((prev) => {
                                                    return { ...prev, weight_unit: e.value }
                                                })
                                            } else {
                                                props.setWeightUnit('')
                                                props.setPackages((prev) => {
                                                    return { ...prev, weight_unit: null }
                                                })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={classes.pricingField}>
                                <MoqInputField
                                    label1="Height"
                                    label2="Width"
                                    label3="Length"
                                    disabled={false}
                                    required={false}
                                    type="number"
                                    value1={props.packages.height}
                                    value2={props.packages.width}
                                    value3={props.packages.length}
                                    onChange1={(e) =>
                                        e
                                            ? props.setPackages((prev) => {
                                                  return { ...prev, height: e.target.value }
                                              })
                                            : props.setPackages((prev) => {
                                                  return { ...prev, height: null }
                                              })
                                    }
                                    onChange2={(e) =>
                                        e
                                            ? props.setPackages((prev) => {
                                                  return { ...prev, width: e.target.value }
                                              })
                                            : props.setPackages((prev) => {
                                                  return { ...prev, width: null }
                                              })
                                    }
                                    onChange3={(e) =>
                                        e
                                            ? props.setPackages((prev) => {
                                                  return { ...prev, length: e.target.value }
                                              })
                                            : props.setPackages((prev) => {
                                                  return { ...prev, length: null }
                                              })
                                    }
                                    inputUnit={true}
                                    unit1={props.measurementUnit.label}
                                    unit2={props.measurementUnit.label}
                                    unit3={props.measurementUnit.label}
                                />
                                <div>
                                    <p>Select Unit</p>
                                    <SelectField
                                        search_by="-- Select unit --"
                                        searchable={false}
                                        options={props.measurementUnitOptions}
                                        value={props.measurementUnit}
                                        selectOnChange={(e) => {
                                            if (e) {
                                                props.setMeasurmentUnit(e)
                                            } else {
                                                props.setMeasurmentUnit('')
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.submitButtonDiv}>
                            <SubmitButton
                                updateSave={updateSave}
                                setUpdateSave={setUpdateSave}
                                updateInformation={props.updatePackage}
                                getPageData={getPageData}
                                type="submit"
                            />
                        </div>
                    </div>
                    {/* </form> */}
                </div>
                <div className={click === 5 ? `${classes.open} ${classes.attribute}` : classes.close}>
                    {/* <form onSubmit={(e) => props.handlePrice}> */}
                    <div>
                        <p
                            onClick={() => {
                                if (click !== 5) {
                                    setClick(5)
                                    props.updateSave && props.setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Specification <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div>
                            <InputField
                                label="Product Size"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.attributes.value}
                                onChange={(e) =>
                                    e
                                        ? props.setAttributes((prev) => {
                                              return { ...prev, value: e.target.value }
                                          })
                                        : props.setAttributes((prev) => {
                                              return { ...prev, value: null }
                                          })
                                }
                            />
                            <InputField
                                label="Material"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.attributes.value2}
                                onChange={(e) =>
                                    e
                                        ? props.setAttributes((prev) => {
                                              return { ...prev, value2: e.target.value }
                                          })
                                        : props.setAttributes((prev) => {
                                              return { ...prev, value2: null }
                                          })
                                }
                            />
                            <InputField
                                label="Color"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.attributes.value3}
                                onChange={(e) =>
                                    e
                                        ? props.setAttributes((prev) => {
                                              return { ...prev, value3: e.target.value }
                                          })
                                        : props.setAttributes((prev) => {
                                              return { ...prev, value3: null }
                                          })
                                }
                            />
                            <InputField
                                label="Supply Ability"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.attributes.value4}
                                onChange={(e) =>
                                    e
                                        ? props.setAttributes((prev) => {
                                              return { ...prev, value4: e.target.value }
                                          })
                                        : props.setAttributes((prev) => {
                                              return { ...prev, value4: null }
                                          })
                                }
                            />
                            <InputField
                                label="Delivery Time"
                                disabled={false}
                                required={false}
                                type="text"
                                value={props.attributes.value5}
                                onChange={(e) =>
                                    e
                                        ? props.setAttributes((prev) => {
                                              return { ...prev, value5: e.target.value }
                                          })
                                        : props.setAttributes((prev) => {
                                              return { ...prev, value5: null }
                                          })
                                }
                            />
                            {props.attribute.map((data, index) => (
                                <div key={index}>
                                    <DoubleInputField
                                        disabled={false}
                                        required={false}
                                        type1="text"
                                        type2="text"
                                        name1="label"
                                        name2="value"
                                        placeholder1="Atrribute name"
                                        placeholder2="Attribute value"
                                        value1={data.label}
                                        value2={data.value}
                                        onChange1={(e) => props.handleInputChange(e, index)}
                                        onChange2={(e) => props.handleInputChange(e, index)}
                                        handleRemoveClick={props.handleRemoveClick}
                                        index={index}
                                    />
                                </div>
                            ))}
                        </div>
                        <button
                            className={classes.btnAdd}
                            type="button"
                            disabled={props.attribute.length > 4 ? true : false}
                            onClick={props.handleAddClick}>
                            + Add more attributes
                        </button>
                    </div>
                    <div className={classes.submitButtonDiv}>
                        <SubmitButton
                            updateSave={updateSave}
                            setUpdateSave={setUpdateSave}
                            updateInformation={props.updateAttribute}
                            getPageData={getPageData}
                            type="submit"
                        />
                    </div>
                </div>
                <div className={click === 6 ? `${classes.open} ${classes.image}` : classes.close}>
                    <div>
                        <p
                            onClick={() => {
                                if (click !== 6) {
                                    setClick(6)
                                    props.updateSave && props.setUpdateSave(false)
                                } else {
                                    setClick(0)
                                }
                            }}
                            className={classes.title}>
                            Upload Image <FiChevronDown className={classes.iconDropdown} />
                        </p>
                        <div className={classes.imgValidation}>
                            <p>The image size must be less than 1mb</p>
                            <p>PNG, JPG (max 500 x 500px)</p>
                        </div>
                        <div className={classes.uploadImage}>
                            <ImageUpload
                                label="Display Image"
                                handleClick={props.handleDisplayClick}
                                image={props.displayImage}
                                setImage={props.setDisplayImage}
                                handleChange={props.handleDisplayChange}
                                imgRef={props.displayImageRef}
                                imageshow="display"
                                currentImage={props.currentImg1}
                            />

                            <div className={classes.subImages}>
                                <ImageUpload
                                    label="Image 2"
                                    handleClick={props.handleImg2Click}
                                    image={props.image2}
                                    setImage={props.setImage2}
                                    inputDisable={false}
                                    handleChange={props.handleImage2Change}
                                    imgRef={props.image2Ref}
                                    currentImage={props.currentImg2}
                                />
                                <ImageUpload
                                    label="Image 3"
                                    handleClick={props.handleImg3Click}
                                    image={props.image3}
                                    setImage={props.setImage3}
                                    inputDisable={false}
                                    handleChange={props.handleImage3Change}
                                    imgRef={props.image3Ref}
                                    currentImage={props.currentImg3}
                                />
                                <ImageUpload
                                    label="Image 4"
                                    handleClick={props.handleImg4Click}
                                    image={props.image4}
                                    setImage={props.setImage4}
                                    inputDisable={false}
                                    handleChange={props.handleImage4Change}
                                    imgRef={props.image4Ref}
                                    currentImage={props.currentImg4}
                                />
                                <ImageUpload
                                    label="Image 5"
                                    handleClick={props.handleImg5Click}
                                    image={props.image5}
                                    setImage={props.setImage5}
                                    inputDisable={false}
                                    handleChange={props.handleImage5Change}
                                    imgRef={props.image5Ref}
                                    currentImage={props.currentImg5}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.submitButtonDiv}>
                        {props.updated ? (
                            <div className={classes.successMsg}>Updated successfully!</div>
                        ) : (
                            <SubmitButton
                                updateSave={updateSave}
                                setUpdateSave={setUpdateSave}
                                updateInformation={props.updateImage}
                                getPageData={getPageData}
                                type="submit"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
