import React from 'react'
import { Body } from '../../Resource'
import classes from './MyRfq.module.css'

export default function MyRfq(props) {
    return (
        <Body>
            <div className={classes.wrapper}>
                <div>My RFQ</div>
                <div>Total: {props.rfqCount}</div>
                {props.rfqCount === 0 ? (
                    <div className={classes.noRfq}>No RFQ has been posted yet.</div>
                ) : (
                    props.rfq?.map((data, id) => (
                        <div className={classes.rfq} key={id}>
                            <div className={classes.sl}>{id + 1}</div>
                            <div className={classes.gridWrap}>
                                <div>
                                    Product Name: <span>{data.BuyerRfq.product_name}</span>
                                </div>
                                <div>
                                    Sourcing Quantity:{' '}
                                    <span>
                                        {data.BuyerRfq.sourcing_quantity} {data.BuyerRfq.measurement_unit}
                                    </span>
                                </div>
                                <div>
                                    Budget Range:{' '}
                                    <span>
                                        {data.BuyerRfq.currency === 'taka'
                                            ? '৳'
                                            : data.BuyerRfq.currency === 'euro'
                                            ? '€'
                                            : data.BuyerRfq.currency === 'dollar'
                                            ? '$'
                                            : ''}{' '}
                                        {data.BuyerRfq.budget_range}
                                    </span>
                                </div>
                                {/* <div>
                                    Phone: <span>+{data.BuyerRfq.phone}</span>
                                </div>
                                <div>
                                    Email: <span>{data.BuyerRfq.email}</span>
                                </div>
                                <div>
                                    Country:{' '}
                                    <span>
                                        {data.BuyerRfq.country_emoji} {data.BuyerRfq.country}
                                    </span>
                                </div> */}
                            </div>
                            <div>
                                Requirements: <span>{data.BuyerRfq.requirements}</span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </Body>
    )
}
