import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/biztrade_logo/logo.png'
import { Auth } from '../../contexts/allContext'
import { apiV1 } from '../../utils/api'
import classes from './Login.module.css'

export default function Login() {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [alertSubmit, setAlertSubmit] = useState(false)
    const [radio, setRadio] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        const details = {
            identifier: email,
            password: password,
        }

        try {
            const url = radio === 'loggedin' ? `${apiV1}/login/in` : `${apiV1}/login/`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(details),
            })

            const log = await response.json()

            if (response.ok) {
                dispatchAuth({ type: 'token', payload: log.access_token })
            } else {
                setAlertSubmit(true)
                setTimeout(function () {
                    setAlertSubmit(false)
                }, 3000)
            }
        } catch {}
    }

    useEffect(() => {
        if (stateAuth.auth) {
            navigate('/dashboard')
        }
    }, [navigate, stateAuth])

    return (
        <div className={classes.wrapper}>
            <div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div>
                        <img src={logo} alt="" />
                        <p>Buyer Dashboard</p>
                    </div>
                    <div className={classes.alertMessage}>
                        {alertSubmit ? 'Please enter valid user and password!' : ''}
                    </div>
                    <div className={classes.inputValue}>
                        <input
                            type="email"
                            placeholder="Enter you email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={classes.inputValue}>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className={classes.keepLogin}>
                        <input
                            type="checkbox"
                            name="loggedin"
                            value="loggedin"
                            onChange={(e) => (radio === 'loggedin' ? setRadio('') : setRadio('loggedin'))}
                            checked={radio === 'loggedin'}
                        />
                        Keep me sign in
                    </div>
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    )
}
