import React from 'react'
import { Body, ComingSoon } from '../Resource'
import classes from './Dashboard.module.css'

export default function Dashboard() {
    return (
        <Body>
            <div className={classes.wrapper}>
                <ComingSoon title="Dashboard" />
            </div>
        </Body>
    )
}
