import React, { useContext, useEffect, useState } from 'react'
import { MdArrowDropDown } from 'react-icons/md'
import { TbLogout } from 'react-icons/tb'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../assets/biztrade_logo/logo.png'
import { Auth, UserInfo } from '../../../contexts/allContext'
import { homeUrl } from '../../../utils/api'
import classes from '../Sidebar/Sidebar.module.css'

export default function Sidebar(props) {
    const { stateAuth, dispatchAuth } = useContext(Auth)
    const { dispatchUser } = useContext(UserInfo)
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [clickLink, setClickLink] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {}, [clickLink])

    const handleClickLink = () => {
        !clickLink && setClickLink(true)
    }

    const handleLogout = async () => {
        dispatchAuth({ type: 'remove' })
        dispatchUser({ type: 'remove' })
        navigate('/')
    }

    return (
        <div className={classes.sidebar}>
            <Link to={`${homeUrl}`}>
                <div className={classes.logoDiv}>
                    <img src={logo} alt="" />
                </div>
            </Link>
            <div>
                {props.sidebarItems.map((item, i) => (
                    <li key={i}>
                        {item.path === '/products' ? (
                            <>
                                <button
                                    className={clickLink ? classes.btnActive : classes.btnInactive}
                                    onClick={() => (!open ? setOpen(true) : setOpen(false))}>
                                    <div className={classes.iconDiv}>{item.icon}</div>{' '}
                                    <div>
                                        <div className={classes.productText}>{item.text}</div>
                                        <MdArrowDropDown
                                            className={
                                                open ? `${classes.iconDropDown} ${classes.open}` : classes.iconDropDown
                                            }
                                        />
                                    </div>
                                </button>
                                <div className={open ? `${classes.dropDown} ${classes.open}` : classes.dropDown}>
                                    <Link to="/product-list" onClick={(e) => handleClickLink(e)}>
                                        Product List/Edit
                                    </Link>
                                    <Link to="/product-add" onClick={(e) => handleClickLink(e)}>
                                        Product Add
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <Link to={item.path}>
                                <div className={location.pathname === item.path ? classes.active : classes.inactive}>
                                    <div className={classes.iconDiv}>{item.icon}</div> <div>{item.text}</div>
                                </div>
                            </Link>
                        )}
                    </li>
                ))}
                <div className={classes.logout}>
                    <button onClick={() => handleLogout()}>
                        <div>
                            <TbLogout className={classes.iconStyle} />
                        </div>
                        <div>Logout</div>
                    </button>
                </div>
                <div className={classes.leftArrow}>{'<'}</div>
                <div className={classes.rightArrow}>{'>'}</div>
            </div>
        </div>
    )
}
