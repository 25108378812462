// import { IoIosArrowDown } from 'react-icons/io'
import Select from 'react-select'

export function SelectField({ value, options, selectOnChange, search_by, selectInputRef, searchable, clearable }) {
    const style = {
        // option: (provided, state) => ({
        //     ...provided,
        //     color: state.isSelected ? 'blue' : 'blue',
        //     backgroundColor: state.isSelected ? 'skyblue' : 'white',
        // }),
        control: (base) => ({
            ...base,
            border: '1px lightgrey solid',
            // This line disable the blue border
            boxShadow: 'none',
            '&:hover': {
                border: '1px grey solid',
            },
        }),
    }

    return (
        <>
            <Select
                ref={selectInputRef}
                options={options}
                value={value}
                onChange={selectOnChange}
                isClearable={clearable ? true : false}
                isSearchable={searchable ? true : false}
                placeholder={search_by}
                maxMenuHeight={'10rem'}
                styles={style}
            />
        </>
    )
}
