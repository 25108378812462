import React, { useContext, useEffect, useRef, useState } from 'react'
import { Layout, ProductList } from '../components'
import { UserInfo, Auth } from '../contexts/allContext'
import { apiV1 } from '../utils/api'

export default function ProductListPage() {
    const [updated, setUpdated] = useState(false)
    const { stateUser } = useContext(UserInfo)
    const { stateAuth } = useContext(Auth)
    const userInfo = stateUser.info
    const [products, setProducts] = useState([])
    const [countData, setCountData] = useState('')
    const [pageSkip, setPageSkip] = useState(0)
    const [slNo, setSlNo] = useState(1)
    // product.length !== 0 ? console.log('yes', product) : console.log('no', product)
    const [check, setCheck] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (userInfo.id) {
                try {
                    const response = await fetch(`${apiV1}/products/seller/${userInfo.id}?skip=${pageSkip}&limit=10`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            // Authorization: `Bearer ${token}`,
                        },
                    })

                    const data = await response.json()
                    const productData = await data

                    if (response.ok) {
                        setCountData(productData[0].results)
                        setProducts(productData[1])
                        setCheck(false)
                    }
                } catch {
                    alert('Please check your internet connection!')
                }
            }
        }
        fetchData()
    }, [userInfo.id, pageSkip, check])

    const handlePrevious = () => {
        if (pageSkip > 0) {
            setSlNo(slNo - 10)
            setPageSkip(pageSkip - 10)
            // setHandleSearchResult(true)
        }
    }

    const handleNext = async () => {
        if (pageSkip < Math.floor(countData / 10) * 10) {
            setSlNo(slNo + 10)
            setPageSkip(pageSkip + 10)
            // setHandleSearchResult(true)
        }
    }

    const [btnClickPopUp, setBtnClickPopUp] = useState('')
    const [activePopUp, setActivePopUp] = useState(false)
    const handleProductEditPopUp = () => {
        setActivePopUp(true)
        setBtnClickPopUp('3')
    }
    // ------------------------------------------========= Set Product Description =========------------------------------------------

    const [productId, setProductId] = useState('')
    const [product, setProduct] = useState({
        title: '',
        description: '',
        quantity: 0,
        sku: '',
    })
    const [price, setPrice] = useState({
        price: 0,
        currency: '',
        moq: 0,
        discount: 0,
        offer_price: 0,
        moq2: 0,
        discount2: 0,
        offer_price2: 0,
        moq3: 0,
        discount3: 0,
        offer_price3: 0,
    })

    const [packages, setPackages] = useState({
        weight: 0,
        weight_unit: '',
        length: 0,
        width: 0,
        height: 0,
        measurement_unit: '',
    })

    const [attributes, setAttributes] = useState({
        name: '',
        value: '',
        name2: '',
        value2: '',
        name3: '',
        value3: '',
        name4: '',
        value4: '',
        name5: '',
        value5: '',
        name6: '',
        value6: '',
        name7: '',
        value7: '',
        name8: '',
        value8: '',
        name9: '',
        value9: '',
        name10: '',
        value10: '',
    })

    // ------------------------------------------========= Set Category =========------------------------------------------

    const [category, setCategory] = useState('')
    const [subCategory, setSubCategory] = useState('')
    const [subSubCategory, setSubSubCategory] = useState('')

    // ------------------------------------------========= Select Unit =========------------------------------------------

    const [currency, setCurrency] = useState({ value: 'dollar', label: '$ - USD', unit: '$' })
    const [weightUnit, setWeightUnit] = useState({ value: 'kg', label: 'kg' })
    const [measurementUnit, setMeasurmentUnit] = useState({ value: 'm', label: 'm' })

    const currencyOptions = [
        { value: 'dollar', label: '$ - USD', unit: '$' },
        { value: 'euro', label: '€ - EUR', unit: '€' },
        { value: 'taka', label: '৳ - BDT', unit: '৳' },
    ]

    const weightUnitOptions = [
        { value: 'kg', label: 'kg' },
        { value: 'lbs', label: 'lbs' },
    ]

    const measurementUnitOptions = [
        { value: 'm', label: 'm' },
        { value: 'cm', label: 'cm' },
    ]

    // ------------------------------------------========= Add more attribute =========------------------------------------------

    const [attribute, setAttribute] = useState([
        {
            label: '',
            value: '',
        },
    ])

    const handleInputChange = (e, index) => {
        const list = [...attribute]
        e ? (list[index][e.target.name] = e.target.value) : (list[index][e.target.name] = '')
        setAttribute(list)
        attribute &&
            setAttributes({
                ...attributes,
                name6: attribute[0] ? attribute[0].label : '',
                value6: attribute[0] ? attribute[0].value : '',
                name7: attribute[1] ? attribute[1].label : '',
                value7: attribute[1] ? attribute[1].value : '',
                name8: attribute[2] ? attribute[2].label : '',
                value8: attribute[2] ? attribute[2].value : '',
                name9: attribute[3] ? attribute[3].label : '',
                value9: attribute[3] ? attribute[3].value : '',
                name10: attribute[4] ? attribute[4].label : '',
                value10: attribute[4] ? attribute[4].value : '',
            })
    }

    const handleAddClick = () => {
        setAttribute([...attribute, { label: '', value: '' }])
    }

    const setAllAttribute = () => {
        setAttributes({
            ...attributes,
            name6: attribute[0] ? attribute[0].label : '',
            value6: attribute[0] ? attribute[0].value : '',
            name7: attribute[1] ? attribute[1].label : '',
            value7: attribute[1] ? attribute[1].value : '',
            name8: attribute[2] ? attribute[2].label : '',
            value8: attribute[2] ? attribute[2].value : '',
            name9: attribute[3] ? attribute[3].label : '',
            value9: attribute[3] ? attribute[3].value : '',
            name10: attribute[4] ? attribute[4].label : '',
            value10: attribute[4] ? attribute[4].value : '',
        })
    }

    const handleRemoveClick = (index) => {
        const list = [...attribute]
        list.splice(index, 1)
        setAttribute(list)
        attribute && setAllAttribute()
    }

    // ------------------------------------------========= Set Product Image =========------------------------------------------

    const [currentImg1, setCurrentImg1] = useState('')
    const [currentImg2, setCurrentImg2] = useState('')
    const [currentImg3, setCurrentImg3] = useState('')
    const [currentImg4, setCurrentImg4] = useState('')
    const [currentImg5, setCurrentImg5] = useState('')

    const [displayImage, setDisplayImage] = useState(null)
    const [image2, setImage2] = useState(null)
    const [image3, setImage3] = useState(null)
    const [image4, setImage4] = useState(null)
    const [image5, setImage5] = useState(null)

    const displayImageRef = useRef(null)
    const image2Ref = useRef(null)
    const image3Ref = useRef(null)
    const image4Ref = useRef(null)
    const image5Ref = useRef(null)

    const handleDisplayClick = () => {
        displayImageRef.current.click()
    }

    const handleImg2Click = () => {
        image2Ref.current.click()
    }

    const handleImg3Click = () => {
        image3Ref.current.click()
    }

    const handleImg4Click = () => {
        image4Ref.current.click()
    }

    const handleImg5Click = () => {
        image5Ref.current.click()
    }

    const handleDisplayChange = (e) => {
        const file = e.target.files[0]
        setDisplayImage(file)
    }

    const handleImage2Change = (e) => {
        const file = e.target.files[0]
        setImage2(file)
    }

    const handleImage3Change = (e) => {
        const file = e.target.files[0]
        setImage3(file)
    }

    const handleImage4Change = (e) => {
        const file = e.target.files[0]
        setImage4(file)
    }

    const handleImage5Change = (e) => {
        const file = e.target.files[0]
        setImage5(file)
    }

    const updateCategory = async () => {
        const details = {
            category_id: category.id,
            sub_category_id: subCategory.id,
            sub_sub_category_id: subSubCategory.id,
        }

        if (productId && stateAuth.token) {
            try {
                const response = await fetch(`${apiV1}/products/product/${productId}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setCheck(true)
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updateProduct = async () => {
        const details = {
            title: product.title,
            description: product.description,
            quantity: product.quantity,
            sku: product.sku,
        }

        if (productId && stateAuth.token) {
            try {
                const response = await fetch(`${apiV1}/products/product/${productId}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                    setCheck(true)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updatePrice = async () => {
        const details = {
            price: price.price,
            currency: currency.value,
            moq: price.moq,
            discount: price.discount,
            offer_price: price.offer_price,
            moq2: price.moq2,
            discount2: price.discount2,
            offer_price2: price.offer_price2,
            moq3: price.moq3,
            discount3: price.discount3,
            offer_price3: price.offer_price3,
        }

        if (productId && stateAuth.token) {
            try {
                const response = await fetch(`${apiV1}/products/price/${productId}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                    setCheck(true)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updatePackage = async () => {
        const details = {
            weight: packages.weight,
            // weight_unit: weightUnit.value,
            length: packages.length,
            width: packages.width,
            height: packages.height,
            // measurement_unit: measurementUnit.value,
        }

        if (productId && stateAuth.token) {
            try {
                const response = await fetch(`${apiV1}/products/package/${productId}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                    setCheck(true)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updateAttribute = async () => {
        const details = {
            name: 'Product size',
            value: attributes.value,
            name2: 'Material',
            value2: attributes.value2,
            name3: 'Color',
            value3: attributes.value3,
            name4: 'Supply Ability  ',
            value4: attributes.value4,
            name5: 'Delivery Time',
            value5: attributes.value5,
            name6: attributes.name6,
            value6: attributes.value6,
            name7: attributes.name7,
            value7: attributes.value7,
            name8: attributes.name8,
            value8: attributes.value8,
            name9: attributes.name9,
            value9: attributes.value9,
            name10: attributes.name10,
            value10: attributes.value10,
        }

        if (productId && stateAuth.token) {
            try {
                const response = await fetch(`${apiV1}/products/attribute/${productId}`, {
                    method: 'PATCH',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${stateAuth.token}`,
                    },
                    body: JSON.stringify(details),
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                    setCheck(true)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    const updateImage = async () => {
        const ImageForm = new FormData()
        displayImage && ImageForm.append('img', displayImage)
        image2 && ImageForm.append('img2', image2)
        image3 && ImageForm.append('img3', image3)
        image4 && ImageForm.append('img4', image4)
        image5 && ImageForm.append('img5', image5)

        if (productId && stateAuth.token && ImageForm) {
            try {
                const response = await fetch(`${apiV1}/products/image/${productId}`, {
                    headers: {
                        Accept: 'application/json',
                        type: 'image/jpeg',
                        // Authorization: `Bearer ${token}`,
                    },
                    method: 'PUT',
                    body: ImageForm,
                })

                const log = await response.json()

                if (response.ok) {
                    console.log('success')
                    setUpdated(true)
                    setTimeout(function () {
                        setUpdated(false)
                    }, 5000)
                    setCheck(true)
                } else {
                    alert(log.context + '!')
                }
            } catch {
                alert('Please check your internet connection!')
            }
        }
    }

    // ------------------------------------------========= Category Option =========------------------------------------------

    const [categoryOption, setCategoryOption] = useState([])
    const [subCategoryOption, setSubCategoryOption] = useState([])
    const [subSubCategoryOption, setSubSubCategoryOption] = useState([])

    useEffect(() => {
        const fetchCategory = async () => {
            const fetchConfigurationGet = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // Authorization: `Bearer ${token}`,
                },
            }
            const categoryOptionList = []
            const subCategoryOptionList = []
            const subSubCategoryOptionList = []
            try {
                const responseCategory = await fetch(`${apiV1}/categories/`, fetchConfigurationGet)

                const data = await responseCategory.json()

                if (responseCategory.ok) {
                    await data.map((e) => categoryOptionList.push({ id: e.id, label: e.name, value: e.name }))
                    setCategoryOption(categoryOptionList)
                    // console.log(category.id)
                    if (category) {
                        const responseSubCategory = await fetch(
                            `${apiV1}/categories/sub_category/${category.id}`,
                            fetchConfigurationGet
                        )

                        const data = await responseSubCategory.json()

                        if (responseSubCategory.ok) {
                            await data.map((e) =>
                                subCategoryOptionList.push({ id: e.id, label: e.name, value: e.name })
                            )
                            setSubCategoryOption(subCategoryOptionList)
                            if (subCategory) {
                                const responseSubSubCategory = await fetch(
                                    `${apiV1}/categories/sub_sub_category/${subCategory.id}`,
                                    fetchConfigurationGet
                                )

                                const data = await responseSubSubCategory.json()

                                if (responseSubSubCategory.ok) {
                                    await data.map((e) =>
                                        subSubCategoryOptionList.push({ id: e.id, label: e.name, value: e.name })
                                    )
                                    setSubSubCategoryOption(subSubCategoryOptionList)
                                }
                            }
                        }
                    }
                }
            } catch {}
        }
        fetchCategory()
    }, [category, subCategory, subSubCategory])

    return (
        <Layout>
            <ProductList
                // All Products
                products={products}
                setPageSkip={setPageSkip}
                pageSkip={pageSkip}
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                slNo={slNo}
                setSlNo={setSlNo}
                countData={countData}
                // Pop up button
                btnClickPopUp={btnClickPopUp}
                activePopUp={activePopUp}
                setActivePopUp={setActivePopUp}
                handleProductEditPopUp={handleProductEditPopUp}
                // category edit
                category={category}
                subCategory={subCategory}
                subSubCategory={subSubCategory}
                setCategory={setCategory}
                setSubCategory={setSubCategory}
                setSubSubCategory={setSubSubCategory}
                categoryOption={categoryOption}
                subCategoryOption={subCategoryOption}
                subSubCategoryOption={subSubCategoryOption}
                // product description edit
                productId={productId}
                setProductId={setProductId}
                product={product}
                setProduct={setProduct}
                // product price edit
                price={price}
                setPrice={setPrice}
                currency={currency}
                setCurrency={setCurrency}
                currencyOptions={currencyOptions}
                // product package edit
                packages={packages}
                setPackages={setPackages}
                weightUnit={weightUnit}
                setWeightUnit={setWeightUnit}
                measurementUnit={measurementUnit}
                setMeasurmentUnit={setMeasurmentUnit}
                measurementUnitOptions={measurementUnitOptions}
                weightUnitOptions={weightUnitOptions}
                // product attribute edit
                attribute={attribute}
                setAttribute={setAttribute}
                attributes={attributes}
                setAttributes={setAttributes}
                handleAddClick={handleAddClick}
                handleRemoveClick={handleRemoveClick}
                handleInputChange={handleInputChange}
                // image edit
                currentImg1={currentImg1}
                currentImg2={currentImg2}
                currentImg3={currentImg3}
                currentImg4={currentImg4}
                currentImg5={currentImg5}
                setCurrentImg1={setCurrentImg1}
                setCurrentImg2={setCurrentImg2}
                setCurrentImg3={setCurrentImg3}
                setCurrentImg4={setCurrentImg4}
                setCurrentImg5={setCurrentImg5}
                displayImage={displayImage}
                image2={image2}
                image3={image3}
                image4={image4}
                image5={image5}
                setDisplayImage={setDisplayImage}
                setImage2={setImage2}
                setImage3={setImage3}
                setImage4={setImage4}
                setImage5={setImage5}
                displayImageRef={displayImageRef}
                image2Ref={image2Ref}
                image3Ref={image3Ref}
                image4Ref={image4Ref}
                image5Ref={image5Ref}
                handleDisplayClick={handleDisplayClick}
                handleImg2Click={handleImg2Click}
                handleImg3Click={handleImg3Click}
                handleImg4Click={handleImg4Click}
                handleImg5Click={handleImg5Click}
                handleDisplayChange={handleDisplayChange}
                handleImage2Change={handleImage2Change}
                handleImage3Change={handleImage3Change}
                handleImage4Change={handleImage4Change}
                handleImage5Change={handleImage5Change}
                // Update fetch
                updateProduct={updateProduct}
                updatePrice={updatePrice}
                updatePackage={updatePackage}
                updateAttribute={updateAttribute}
                updateImage={updateImage}
                updateCategory={updateCategory}
                updated={updated}
            />
        </Layout>
    )
}
