import React, { useState } from 'react'
import { UserInfo } from '../../contexts/allContext'
import { apiV1 } from '../../utils/api'
import { Body } from '../Resource'
import BusinessInformation from './BusinessInformation/BusinessInformation'
import CompanyInformation from './CompanyInformation/CompanyInformation'
import LogoBanner from './LogoBanner/LogoBanner'
import Mydocs from './Mydocs/Mydocs'
import classes from './PageSettings.module.css'

export default function PageSettings(props) {
    const [tab, setTab] = useState(1)
    const [currentLogo, setCurrentLogo] = useState('')
    const [currentBanner, setCurrentBanner] = useState('')

    const getImage = async () => {
        const responseBusiness = await fetch(`${apiV1}/companies/${props.userInfo.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
        })
        const data_Business = await responseBusiness.json()
        const logo = await data_Business.ImgLogo
        const banner = await data_Business.ImgBanner
        // const banner = await data_Business.ImgBanner
        if (responseBusiness.ok) {
            props.setImageLogo('')
            props.setImageBanner('')
            setCurrentLogo(logo.image_string)
            setCurrentBanner(banner.image_string)
        }
    }

    return (
        <Body>
            <div className={classes.innerBody}>
                <p>Page Settings</p>
                <div>
                    <div>
                        <button className={tab === 1 ? classes.tab : ''} onClick={() => setTab(1)}>
                            Page Setup
                        </button>
                        <button className={tab === 2 ? classes.tab : ''} onClick={() => setTab(2)}>
                            Company Information
                        </button>
                        <button
                            className={tab === 3 ? classes.tab : ''}
                            onClick={() => {
                                setTab(3)
                                getImage()
                            }}>
                            Logo and Banner
                        </button>
                        <button className={tab === 4 ? classes.tab : ''} onClick={() => setTab(4)}>
                            My Docs
                        </button>
                    </div>
                    <div>
                        {tab === 1 && (
                            <CompanyInformation
                                categoryOptions={props.categoryOptions}
                                pageName={props.pageName}
                                country={props.country}
                                city={props.city}
                                address={props.address}
                                category={props.category}
                                about={props.about}
                                pocName={props.pocName}
                                pocPhone={props.pocPhone}
                                designation={props.designation}
                                setPageName={props.setPageName}
                                setCountry={props.setCountry}
                                setCity={props.setCity}
                                setAddress={props.setAddress}
                                setCategory={props.setCategory}
                                setAbout={props.setAbout}
                                setPocName={props.setPocName}
                                setPocPhone={props.setPocPhone}
                                setDesignation={props.setDesignation}
                                updateCompanyInformation={props.updateCompanyInformation}
                                getPageData={props.getPageData}
                            />
                        )}
                        {tab === 2 && (
                            <BusinessInformation
                                registrationDate={props.registrationDate}
                                yearsExport={props.yearsExport}
                                annualExport={props.annualExport}
                                floorSpace={props.floorSpace}
                                productionMachine={props.productionMachine}
                                productionLines={props.productionLines}
                                totalAnnualOutput={props.totalAnnualOutput}
                                supplyChain={props.supplyChain}
                                mainMarket={props.mainMarket}
                                customization={props.customization}
                                engineer={props.engineer}
                                setRegistrationDate={props.setRegistrationDate}
                                setYearsExport={props.setYearsExport}
                                setAnnualExport={props.setAnnualExport}
                                setFloorSpace={props.setFloorSpace}
                                setProductionMachine={props.setProductionMachine}
                                setProductionLines={props.setProductionLines}
                                setTotalAnnualOutput={props.setTotalAnnualOutput}
                                setSupplyChain={props.setSupplyChain}
                                setMainMarket={props.setMainMarket}
                                setCustomization={props.setCustomization}
                                setEngineer={props.setEngineer}
                                updateBusinessInformation={props.updateBusinessInformation}
                                getPageData={props.getPageData}
                            />
                        )}
                        {tab === 3 && (
                            <LogoBanner
                                updateLogo={props.updateLogo}
                                imageLogo={props.imageLogo}
                                setImageLogo={props.setImageLogo}
                                handleLogoClick={props.handleLogoClick}
                                handleLogoChange={props.handleLogoChange}
                                logoRef={props.logoRef}
                                updateBanner={props.updateBanner}
                                imageBanner={props.imageBanner}
                                setImageBanner={props.setImageBanner}
                                handleBannerClick={props.handleBannerClick}
                                handleBannerChange={props.handleBannerChange}
                                bannerRef={props.bannerRef}
                                currentLogo={currentLogo}
                                currentBanner={currentBanner}
                                imageAlert={props.imageAlert}
                            />
                        )}

                        {tab === 4 && (
                            <Mydocs
                                updateLogo={props.updateLogo}
                                imageLogo={props.imageLogo}
                                setImageLogo={props.setImageLogo}
                                handleLogoClick={props.handleLogoClick}
                                handleLogoChange={props.handleLogoChange}
                                logoRef={props.logoRef}
                                updateBanner={props.updateBanner}
                                imageBanner={props.imageBanner}
                                setImageBanner={props.setImageBanner}
                                handleBannerClick={props.handleBannerClick}
                                handleBannerChange={props.handleBannerChange}
                                bannerRef={props.bannerRef}
                                currentLogo={currentLogo}
                                currentBanner={currentBanner}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Body>
    )
}
