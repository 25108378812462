import React, { useState } from 'react'
import { InputField, SubmitButton } from '../../Resource'
import { SelectField } from '../../Resource/SelectField/SelectField'
import classes from './Mydocs.module.css'

export default function Mydocs(props) {
    const [pdfFile, setPdfFile] = useState(null)
    const [pdfFileError, setPdfFileError] = useState('')

    return (
        <div className={classes.wrapper}>
            <div>
                <p>My docs</p>
                {/* <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateCompanyInformation}
                        getPageData={props.getPageData}
                    />
                </div> */}
            </div>
            <div className={classes.mydocs}>
                <div className={classes.personal}>
                    <div>
                        Personal Documents
                        {/* <div>+Add</div> */}
                    </div>
                    <div>
                        coming soon...
                        {/* <input type="file" name="personal" onChange={props.changeHandler} /> */}
                    </div>
                </div>
                <div className={classes.legal}>
                    <div>Legal Documents</div>
                    <div>coming soon..</div>
                </div>
                <div className={classes.banking}>
                    <div>Banking Documents</div>
                    <div>coming soon...</div>
                </div>
            </div>
            {/* <div>
                <div>
                    <SubmitButton
                        updateSave={updateSave}
                        setUpdateSave={setUpdateSave}
                        updateInformation={props.updateCompanyInformation}
                        getPageData={props.getPageData}
                    />
                </div>
            </div> */}
        </div>
    )
}
